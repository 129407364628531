import { generateService, BASE_URL, handleErrors } from "./Util";
import { fetchAuthentication } from "./Client";
import * as SQLService from "./sql.service";

function handleSchema(dbSchema) {
  const tables = [...new Set(dbSchema.map((r) => r.table_name))];
  return tables.map((tab) => {
    return {
      name: tab,
      schema: "public",
      type: "table",
      columns: dbSchema
        .filter((s) => s.table_name === tab)
        .map((r) => {
          return {
            name: r.column_name,
            type: SQLService.getJavaScriptType(r.data_type),
          };
        }),
    };
  });
}

export const generateNewDatasource = () => {
  return {
    name: "Untitled",
    resources: [],
    relations: [],
    createdAt: new Date().getTime(),
  };
};

const service = generateService("KSTKReportingDatasources", generateNewDatasource);

export const getDatasources = service.get;
export const getDatasourceById = service.getById;
export const createDatasource = service.post;
export const updateDatasource = service.put;
export const deleteDatasource = service.delete;

export const generateFromOrg = (orgId) => {
  return fetch(`${BASE_URL}/KSTKReportingDatasources/getResourcesFromOrg/${orgId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: fetchAuthentication().value.id,
    },
  })
    .then(handleErrors)
    .then(handleSchema);
};

export const getResourceUniqueName = (resource) => {
  return `${resource.schema}.${resource.name}`;
};

export const isEqualsWithUniqueName = (resource, uniqueName) => {
  let [schema, source] = uniqueName.split(".");

  return resource.schema === schema && resource.name === source;
};
