import React, { useContext, useEffect, useState, useCallback } from "react";
import { ReportContext } from "./ReportContext";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { aggregators } from "../../util/kpi.util";

export function KSTKTable(props) {
  const { report, selection, filter, selectedSource, datasource, layout, pages } = useContext(ReportContext);

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [defaultSortInfo, setDefaultSortInfo] = useState([]);

  useEffect(() => {
    /* setDefaultSortInfo(getDefaultSortInfo()); */
    setTimeout(() => {
      sortAndSetTableData(getDefaultSortInfo(), []);
      getRowAggregators([]);
    }, 1000);
  }, []);

  //useEffect(() => {
  //  if (props.data?.datasets?.length) {
  //    let tableRows = [];

  //    props.data?.datasets?.forEach((ds, dsIndex) => {
  //      ds.data.forEach((dataEntry, dataEntryIndex) => {
  //        if (!tableRows[dataEntryIndex]) {
  //          tableRows[dataEntryIndex] = {};
  //        }
  //        if (typeof props.metadata?.values[dsIndex]?.field === "object") {
  //          tableRows[dataEntryIndex][props.metadata?.values[dsIndex]?.field.name] = dataEntry;
  //        } else {
  //          tableRows[dataEntryIndex][props.metadata?.values[dsIndex]?.field?.split(".")[1]] = dataEntry;
  //        }
  //      });
  //    });

  //    setDefaultSortInfo(getDefaultSortInfo());
  //    setTimeout(() => {
  //      sortAndSetTableData(getDefaultSortInfo(), tableRows);
  //      getRowAggregators(tableRows);
  //    }, 1000);
  //  }
  //}, [props.data]);

  useEffect(() => {
    setGroupingAndPivotColumns();
  }, [props.metadata, props.dataMode]);

  const setGroupingAndPivotColumns = () => {
    if (
      props.metadata != null &&
      props.metadata.columnGrouping != null &&
      props.metadata.columnGrouping.field != null &&
      props.metadata.columnGrouping.field.length > 0
    ) {
      setGroupBy(
        props.metadata.columnGrouping.field.map((c) => {
          const val = props.metadata.values.find((v) => v.field === c);
          if (datasource.sourceType === "mongo") {
            return val.field.split(".")[1];
          } else if (datasource.sourceType === "postgres") {
            return (
              (val.aggregator && val.aggregator.length ? val.aggregator + "_" : "") +
              props.getSourceByDataMode(c.replace(".", "_"))
            );
          } else {
            console.error("unkown sourcetype");
            return "";
          }
        })
      );
    } else {
      setGroupBy([]);
    }

    if (
      groupBy != null &&
      groupBy.length > 0 &&
      props.metadata.columnPivotColumns != null &&
      props.metadata.columnPivotColumns.field != null &&
      props.metadata.columnPivotColumns.field.length > 0
    ) {
      setPivot(
        props.metadata.columnPivotColumns.field.map((c) => {
          //return c.split(".")[1];
          const val = props.metadata.values.find((v) => v.field === c);
          if (datasource.sourceType === "mongo") {
            return c.split(".")[1];
          } else if (datasource.sourceType === "postgres") {
            return (
              (val.aggregator && val.aggregator.length ? val.aggregator + "_" : "") +
              props.getSourceByDataMode(c.replace(".", "_"))
            );
          } else {
            console.error("unkown sourcetype");
            return "";
          }
        })
      );
      setEnablePivot(true);
    } else {
      setPivot([]);
      setEnablePivot(false);
    }
  };

  useEffect(() => {
    if (props.metadata != null) {
      let columns = props.metadata?.values?.map((v) => {
        let fieldColumnTotal;
        if (props.metadata.columnAggregators && props.metadata.columnAggregators.length > 0) {
          fieldColumnTotal = props.metadata.columnAggregators.filter((el) => el.field == v.field);
        }
        let filteredColumnSettings;
        if (props.metadata.columnSettings != null) {
          filteredColumnSettings = props.metadata.columnSettings.filter(
            (el) => el.column == (typeof v.field == "object" ? v.field.name : v.field)
          );
          filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
        }
        return {
          groupSummaryReducer: getGroupSummaryReducerByField(v.field),
          name: (() => {
            if (datasource.sourceType === "mongo") {
              return v.field.split(".")[1];
            } else if (datasource.sourceType === "postgres") {
              return (
                (v.aggregator && v.aggregator.length ? v.aggregator + "_" : "") +
                (v.name && v.name.length
                  ? props.getSourceByDataMode(v.name)
                  : v.field.name && v.field.name.length
                  ? v.field.name
                  : typeof v.field === "object" && v.field.variableName
                  ? props.getSourceByDataMode(v.field.variableName.replace("$$ ", ""))
                  : props.getSourceByDataMode(v.field.replace(".", "_")))
              );
            } else {
              console.error("unknown datatype");
              return "";
            }
          })(),
          header: getHeader(v),
          defaultFlex: filteredColumnSettings == null || filteredColumnSettings.width == null ? 1 : 0,
          width:
            filteredColumnSettings != null && filteredColumnSettings.width != null
              ? filteredColumnSettings.width
              : null,
          headerProps: {
            className: props.metadata.headerFontBold ? "boldFontWeight" : "normalFontWeight",
            style: {
              fontSize: props.metadata.headerFontSize ? props.metadata.headerFontSize + "px" : "12px",
              fontFamily: props.metadata.headerFontFamily
                ? props.metadata.headerFontFamily
                : "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
              color: props.metadata.headerTextColor ? props.metadata.headerTextColor : null,
              /* backgroundColor: props.metadata.headerBackgroundColor ? props.metadata.headerBackgroundColor : null,
               */
              borderRight: props.metadata.showPivotCellsHeader ? "" : "1px solid white",
              borderLeft: props.metadata.showPivotCellsHeader ? "" : "1px solid white",
              backgroundColor: "rgb(232, 232, 232)",
              fontStyle: props.metadata.headerFontItalic ? "italic" : "initial",
              textDecoration: props.metadata.headerFontUnderline ? "underline" : "none",
            },
          },
          headerAlign: "center",
          sortable: filteredColumnSettings != null && filteredColumnSettings.sort != null ? true : false,
          columnTotalFunction: fieldColumnTotal && fieldColumnTotal.length > 0 ? fieldColumnTotal[0].aggregator : null,
          cellProps: {
            className: getCellPropsClassNames(filteredColumnSettings, props.metadata),
            style: {
              fontSize: getCellStyle("fontSize", props.metadata, filteredColumnSettings),
              fontFamily: getCellStyle("fontFamily", props.metadata, filteredColumnSettings),
              color: getCellStyle("color", props.metadata, filteredColumnSettings),
              backgroundColor: getCellStyle("backgroundColor", props.metadata, filteredColumnSettings),
              fontStyle: getCellStyle("fontStyle", props.metadata, filteredColumnSettings),
              textDecoration: getCellStyle("textDecoration", props.metadata, filteredColumnSettings),
            },
          },
          render: ({ value, data }) => (
            //data.__group && !data.leaf in order to get grouping columns that are not the last one
            <span
              style={{
                fontSize: getCellStyle("fontSize", props.metadata, filteredColumnSettings),
                fontFamily: getCellStyle("fontFamily", props.metadata, filteredColumnSettings),
                color: getCellStyle("color", props.metadata, filteredColumnSettings),
                backgroundColor: getCellStyle("backgroundColor", props.metadata, filteredColumnSettings),
                fontStyle: getCellStyle("fontStyle", props.metadata, filteredColumnSettings),
                textDecoration: getCellStyle("textDecoration", props.metadata, filteredColumnSettings),
              }}
            >
              {props.metadata.hideGroupingColumnsAggregators && data.__group && !data.leaf
                ? null
                : value != null
                ? value +
                  (filteredColumnSettings != null &&
                  filteredColumnSettings.suffix != null &&
                  filteredColumnSettings.suffix != ""
                    ? filteredColumnSettings.suffix
                    : "")
                : null}
            </span>
          ),
        };
      });

      if (
        props.metadata.rowAggregators &&
        props.metadata.rowAggregators.field &&
        props.metadata.rowAggregators.field.length > 0
      ) {
        columns.push({
          name: "total",
          header: props.metadata.columnAggregatorLabel ? props.metadata.columnAggregatorLabel : "Total",
          defaultFlex: 1,
          headerProps: {
            className: props.metadata.headerFontBold ? "boldFontWeight" : "normalFontWeight",
            style: {
              fontSize: props.metadata.headerFontSize ? props.metadata.headerFontSize + "px" : "12px",
              fontFamily: props.metadata.headerFontFamily
                ? props.metadata.headerFontFamily
                : "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
              color: props.metadata.headerTextColor ? props.metadata.headerTextColor : null,
              /* backgroundColor: props.metadata.headerBackgroundColor ? props.metadata.headerBackgroundColor : null, */
              fontWeight: props.metadata.headerFontBold ? 700 : 400,
              /* borderRight: "1px solid white",
              borderLeft: "1px solid white", */
              backgroundColor: "rgb(232, 232, 232)",
              fontStyle: props.metadata.headerFontItalic ? "italic" : "initial",
              textDecoration: props.metadata.headerFontUnderline ? "underline" : "none",
            },
          },
          headerAlign: "center",
          cellProps: {
            className: "normalFontWeight",
          },
          columnTotalFunction: props.metadata.columnAggregatorFunction ? props.metadata.columnAggregatorFunction : null,
        });
      }
      setTableColumns(columns);
    }
  }, [props.metadata, props.dataMode]);

  useEffect(() => {
    if (props.startExportTableData) {
      props.exportTableData(props.metadata, layout).then((url) => {
        props.onExportTableDataEnd(url);
      });
    }
  }, [props.startExportTableData]);

  const getHeader = (v) => {
    if (v.seriesName) {
      return v.seriesName;
    } else if (typeof v.field === "object" && v.field.name && v.field.name.length) {
      return v.field.name;
    } else {
      return (
        (getAggregatorLabel(v.aggregator) ? getAggregatorLabel(v.aggregator) + " " : "") +
        (typeof v.field === "object" && v.field.variableName
          ? v.field.variableName.replace("$$ ", "")
          : getCustomHeader(v.field.split(".")[0], v.field.split(".")[1]))
      );
    }
  };

  const getComparer = (sortInfo) => {
    return (a, b) => {
      let comparerResult;
      for (let j = 0; j < sortInfo.length; j++) {
        let sort = sortInfo[j];
        let parsedA =
          !isNaN(Number(a[sort.name])) && a[sort.name] != null
            ? Number(a[sort.name])
            : a[sort.name] != null && !isNaN(Number(a[sort.name].replace(/%/g, "")))
            ? Number(a[sort.name].replace(/%/g, ""))
            : a[sort.name] == null
            ? null
            : a[sort.name].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let parsedB =
          !isNaN(Number(b[sort.name])) && b[sort.name] != null
            ? Number(b[sort.name])
            : b[sort.name] != null && !isNaN(Number(b[sort.name].replace(/%/g, "")))
            ? Number(b[sort.name].replace(/%/g, ""))
            : b[sort.name] == null
            ? null
            : b[sort.name].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        if (parsedA === null) {
          comparerResult = comparerResult || 1;
          continue;
        }
        if (parsedB === null) {
          comparerResult = comparerResult || -1;
          continue;
        }
        if (parsedA === parsedB) {
          comparerResult = comparerResult || 0;
          continue;
        }
        if (!isNaN(sort.dir)) {
          return parsedB > parsedA ? -sort.dir : sort.dir;
        } else {
          if (sort.dir == "asc") {
            comparerResult = comparerResult || parsedB > parsedA ? -1 : 1;
            continue;
          } else {
            comparerResult = comparerResult || parsedB > parsedA ? 1 : -1;
            continue;
          }
        }
      }
      return comparerResult;
    };
  };

  const getCellPropsClassNames = (filteredColumnSettings, metadata) => {
    let cellClassNames = "";
    if (filteredColumnSettings != null && filteredColumnSettings.cellFontBold) {
      cellClassNames += " " + "boldFontWeight";
    } else if (metadata != null && metadata.cellFontBold) {
      cellClassNames += " " + "boldFontWeight";
    } else {
      cellClassNames += " " + "normalFontWeight";
    }
    if (filteredColumnSettings != null && filteredColumnSettings.cellTextAlign) {
      cellClassNames += " " + filteredColumnSettings.cellTextAlign + "TextAlign";
    } else if (metadata != null && metadata.cellTextAlign) {
      cellClassNames += " " + metadata.cellTextAlign + "TextAlign";
    }
    return cellClassNames;
  };

  const getCellStyle = (attribute, globalStyle, columnStyle) => {
    if (attribute == "fontSize") {
      if (columnStyle != null && columnStyle.cellFontSize != null && columnStyle.cellFontSize != "") {
        return columnStyle.cellFontSize + "px";
      } else if (globalStyle != null && globalStyle.cellFontSize != null && globalStyle.cellFontSize != "") {
        return globalStyle.cellFontSize + "px";
      } else {
        return "12px";
      }
    } else if (attribute == "fontFamily") {
      if (columnStyle != null && columnStyle.cellFontFamily != null && columnStyle.cellFontFamily != "") {
        return columnStyle.cellFontFamily;
      } else if (globalStyle != null && globalStyle.cellFontFamily != null && globalStyle.cellFontFamily != "") {
        return globalStyle.cellFontFamily;
      } else {
        return "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
      }
    } else if (attribute == "color") {
      if (columnStyle != null && columnStyle.cellTextColor != null) {
        return columnStyle.cellTextColor;
      } else if (globalStyle != null && globalStyle.cellTextColor != null) {
        return globalStyle.cellTextColor;
      } else {
        return null;
      }
    } else if (attribute == "backgroundColor") {
      if (columnStyle != null && columnStyle.cellBackgroundColor != null) {
        return columnStyle.cellBackgroundColor;
      } else if (globalStyle != null && globalStyle.cellBackgroundColor != null) {
        return globalStyle.cellBackgroundColor;
      } else {
        return null;
      }
    } else if (attribute == "fontStyle") {
      if (columnStyle != null && columnStyle.cellFontItalic != null) {
        if (columnStyle.cellFontItalic) {
          return "italic";
        } else {
          return "initial";
        }
      } else if (globalStyle != null && globalStyle.cellFontItalic != null) {
        if (globalStyle.cellFontItalic) {
          return "italic";
        } else {
          return "initial";
        }
      }
    } else if (attribute == "textDecoration") {
      if (columnStyle != null && columnStyle.cellFontUnderline != null) {
        if (columnStyle.cellFontUnderline) {
          return "underline";
        } else {
          return "none";
        }
      }
    } else if (attribute == "textAlign") {
      if (columnStyle != null && columnStyle.cellTextAlign != null) {
        if (columnStyle.cellTextAlign == "start") {
          return "left";
        } else if (columnStyle.cellTextAlign == "center") {
          return "center";
        } else {
          return "right";
        }
      }
    } else if (attribute == "justifyContent") {
      if (columnStyle != null && columnStyle.cellTextAlign != null) {
        return columnStyle.cellTextAlign;
      }
    } else if (globalStyle != null && globalStyle.cellFontUnderline != null) {
      if (globalStyle.cellFontUnderline) {
        return "underline";
      } else {
        return "none";
      }
    } else {
      return "none";
    }
  };

  const sortAndSetTableData = (sortInfo, tableRows) => {
    let newData = [];
    let pivotGroupingSortInfo = [];

    if (sortInfo.length > 0 && tableRows != null && tableRows.length > 0) {
      sortInfo = parseDefaultSortInfoFields(sortInfo, Object.keys(tableRows[0]));
    }

    /* if (
      props.metadata != null &&
      props.metadata.columnGrouping != null &&
      props.metadata.columnGrouping.field != null &&
      props.metadata.columnGrouping.field.length > 0
    ) {
      pivotGroupingSortInfo = pivotGroupingSortInfo.concat(
        props.metadata.columnGrouping.field.map((el) => {
          return { name: el.split(".")[1], dir: 1 };
        })
      );
    }
    if (
      props.metadata != null &&
      props.metadata.columnPivotColumns != null &&
      props.metadata.columnPivotColumns.field != null &&
      props.metadata.columnPivotColumns.field.length > 0
    ) {
      pivotGroupingSortInfo = pivotGroupingSortInfo.concat(
        props.metadata.columnPivotColumns.field.map((el) => {
          return { name: el.split(".")[1], dir: 1 };
        })
      );
    } */
    if (pivotGroupingSortInfo.length > 0) {
      newData = !pivotGroupingSortInfo
        ? [].concat(tableRows)
        : [].concat(tableRows).sort(getComparer(pivotGroupingSortInfo));
    } else {
      newData = !sortInfo ? [].concat(tableRows) : [].concat(tableRows).sort(getComparer(sortInfo));
    }
    return newData;
  };

  const parseDefaultSortInfoFields = (sortInfo, dataFields) => {
    let parsedSortInfo = [];

    sortInfo.forEach((sI) => {
      let filteredDataField = dataFields.filter((el) =>
        el.indexOf("_") != -1 ? el.indexOf(sI.name) != -1 : el.indexOf("_" + sI.name) != -1
      );
      if (filteredDataField.length > 0) {
        parsedSortInfo.push({ name: filteredDataField[0], dir: sI.dir });
      } else {
        if (datasource.sourceType == "mongo" || sI.name.indexOf("##") != -1) {
          parsedSortInfo.push(sI);
        }
      }
    });

    return parsedSortInfo;
  };

  const getGroupSummaryReducerByField = (fieldName) => {
    if (
      props.metadata != null &&
      props.metadata.columnGrouping != null &&
      props.metadata.columnGrouping.field != null &&
      props.metadata.columnGrouping.field.length > 0 &&
      props.metadata.columnPivotColumns != null &&
      props.metadata.columnPivotColumns.field != null &&
      props.metadata.columnPivotColumns.field.length > 0
    ) {
      if (props.metadata.columnPivotsAggregators != null && props.metadata.columnPivotsAggregators.length > 0) {
        let filteredColumnPivot = props.metadata.columnPivotsAggregators.filter(
          (el) => el.field == (typeof fieldName == "string" ? fieldName : fieldName.name)
        );
        if (filteredColumnPivot.length > 0) {
          if (datasource.sourceType == "postgres") {
            return genericPostgresReducer;
          }
          switch (filteredColumnPivot[0].aggregator) {
            case "count":
              return countReducer;
            case "sum":
              return sumReducer;
            case "avg":
              return avgReducer;
            default:
              return countReducer;
          }
        }
      } else if (props.metadata.columnPivotColumns.field.indexOf(fieldName) == -1) {
        return countReducer;
      }
    } else {
      return countReducer;
    }
  };

  const getCustomHeader = (tableName, columnName) => {
    const resourceId = datasource.resources.find((r) => r.name === tableName)?.id;
    const customHeader = datasource.customHeaders[resourceId]?.find((c) => c.name === columnName)?.header;
    return customHeader || columnName;
  };

  const getAggregatorLabel = (agg) => {
    const aggregators = [
      { value: "sum", label: "Som." },
      { value: "avg", label: "Média" },
      { value: "count", label: "Total" },
      { value: "count_dist", label: "Total (únicos)" },
      { value: "max", label: "Max" },
      { value: "min", label: "Min" },
    ];

    return aggregators.find((a) => a.value === agg)?.label;
  };
  const rowStyle = {
    /*  whiteSpace: "pre-wrap !important", */
  };

  const getRowAggregators = (tableRows) => {
    tableRows.forEach((row) => {
      if (
        props.metadata.rowAggregators &&
        props.metadata.rowAggregators.field &&
        props.metadata.rowAggregators.field.length > 0
      ) {
        let total;
        if (props.metadata.rowAggregators.aggregator == "sum" || props.metadata.rowAggregators.aggregator == "avg") {
          total = 0;
          props.metadata.rowAggregators.field.forEach((field) => (total += Number(row[field.split(".")[1]])));
        }
        if (props.metadata.rowAggregators.aggregator == "max") {
          props.metadata.rowAggregators.field.forEach(
            (field) =>
              (total = total ? Math.max(total, Number(row[field.split(".")[1]])) : Number(row[field.split(".")[1]]))
          );
        }
        if (props.metadata.rowAggregators.aggregator == "min") {
          props.metadata.rowAggregators.field.forEach(
            (field) =>
              (total = total ? Math.min(total, Number(row[field.split(".")[1]])) : Number(row[field.split(".")[1]]))
          );
        }
        if (props.metadata.rowAggregators.aggregator == "min") {
          props.metadata.rowAggregators.field.forEach(
            (field) =>
              (total = total ? Math.min(total, Number(row[field.split(".")[1]])) : Number(row[field.split(".")[1]]))
          );
        }
        if (props.metadata.rowAggregators.aggregator == "avg") {
          total = (Math.round((total * 100) / props.metadata.rowAggregators.field.length) / 100).toFixed(2);
        }
        row.total = total;
      }
    });
  };

  const getColumnGroupByName = (group) => {
    return columnGroups[group];
  };

  const columnNameStartsWithAgg = (colName) => {
    const aggCodes = aggregators.map((a) => a.value);
    let found = false;

    aggCodes.forEach((c) => {
      if (colName.indexOf(c) === 0) {
        found = true;
      }
    });

    return found;
  };

  const footerRows = (col) => {
    if (
      props.metadata.columnAggregators &&
      props.metadata.columnAggregators.length > 0 &&
      props.metadata?.pivotAggregatorField == null
    ) {
      let footerRow = {};
      tableColumns.forEach((tableColumnDetails) => {
        //enrich tableColumnDetails with Measure info
        const caMeasure = props.metadata.columnAggregators.filter((ca) => ca.field === tableColumnDetails.name)[0];
        if (tableColumnDetails.columnTotalFunction == null && caMeasure && caMeasure.aggregator) {
          tableColumnDetails.columnTotalFunction = caMeasure?.aggregator;
        }

        let valueField = props.metadata.values?.filter((el) =>
          typeof el.field == "object"
            ? tableColumnDetails.name.indexOf(props.getSourceByDataMode(el.field.name.replace(".", "_"))) != -1
            : tableColumnDetails.name.indexOf(props.getSourceByDataMode(el.field.replace(".", "_"))) != -1
        );
        let filteredColumnSettings;
        if (props.metadata.columnSettings != null) {
          filteredColumnSettings = props.metadata.columnSettings.filter(
            (el) =>
              el.column ==
              props.getSourceByDataMode(
                typeof valueField[0]?.field == "object" ? valueField[0]?.field.name : valueField[0]?.field
              )
          );
          filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
        }
        if (tableColumnDetails.columnTotalFunction) {
          let regex = new RegExp("^" + tableColumnDetails.columnTotalFunction + "_", "gi");
          let cellStyle = {};
          cellStyle.display = "flex";
          cellStyle.width = "100%";
          cellStyle.fontSize = getCellStyle("fontSize", props.metadata, filteredColumnSettings);
          cellStyle.color = getCellStyle("color", props.metadata, filteredColumnSettings);
          cellStyle.backgroundColor = getCellStyle("backgroundColor", props.metadata, filteredColumnSettings);
          cellStyle.fontStyle = getCellStyle("fontStyle", props.metadata, filteredColumnSettings);
          cellStyle.textDecoration = getCellStyle("textDecoration", props.metadata, filteredColumnSettings);
          cellStyle.justifyContent = getCellStyle("justifyContent", props.metadata, filteredColumnSettings);
          footerRow[tableColumnDetails.name] = ({ summary }) => (
            <div style={cellStyle}>
              {getAggregatorLabel(tableColumnDetails.columnTotalFunction)}:
              <b>
                {datasource.sourceType == "mongo"
                  ? inPlaceDataTypeConversion(
                      summary[tableColumnDetails.name],
                      valueField[0]?.dataType,
                      valueField[0]?.decimalPlaces
                    ) +
                    (filteredColumnSettings != null &&
                    filteredColumnSettings.suffix != null &&
                    filteredColumnSettings.suffix != ""
                      ? filteredColumnSettings.suffix
                      : "")
                  : getColumnGroupByName(
                      tableColumnDetails.columnTotalFunction +
                        "_" +
                        (columnNameStartsWithAgg(tableColumnDetails.name)
                          ? tableColumnDetails.name.substring(tableColumnDetails.name.indexOf("_") + 1)
                          : tableColumnDetails.name.replace(regex, ""))
                    )}
              </b>
            </div>
          );
        }
      });

      return [
        {
          render: footerRow,
          cellStyle: {
            fontSize: "12px",
          },
        },
      ];
    } else if (props.metadata?.pivotAggregatorField != null) {
      let filteredColumnSettings;
      if (props.metadata.columnSettings != null) {
        filteredColumnSettings = props.metadata.columnSettings.filter(
          (el) => el.column == props.metadata?.pivotAggregatorField
        );
        filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
      }
      return [
        {
          render: ({ column, summary }) => {
            const { pivotColumnPath, pivotSummaryPath, pivotGrandSummaryColumn, groupColumn } = column;

            let style = {};
            if (groupColumn) {
              return (
                <div>
                  <b>Total</b>
                </div>
              );
            }

            if (pivotColumnPath && pivotColumnGroups.length) {
              //TODO AV
              let result = 0;
              style.justifyContent = "right";
              try {
                const pivotGroup = column.group.split(":");
                const columnNames = Object.keys(pivotColumnGroups[0]);
                const pivotGroupColumnName = columnNames.filter((cns) => pivotGroup[0].includes(cns));
                const firstPivotColumnUnderscore = pivotColumnPath[pivotColumnPath.length - 1].indexOf("_") + 1;
                const pivotColumnWithoutAggregator =
                  pivotColumnPath[pivotColumnPath.length - 1].substring(firstPivotColumnUnderscore);
                const pivotColumnWithAggregator = columnNames.filter((cns) =>
                  cns.includes(`_${pivotColumnWithoutAggregator}`)
                );
                result = pivotColumnGroups.find((pvg) => pvg[pivotGroupColumnName[0]] == pivotGroup[1])[
                  pivotColumnWithAggregator
                ];
              } catch (err) {
                //props.publishNotification({
                //    type: "alert",
                //    message: `Error (Table -> ${props.metadata.title}): ${err?.error?.message || err}`,
                //  });
              }
              return (
                <div style={style}>
                  <b>{result || 0}</b>
                </div>
              );
            }
          },
          cellStyle: {
            fontSize: getCellStyle("fontSize", props.metadata, filteredColumnSettings),
            color: getCellStyle("color", props.metadata, filteredColumnSettings),
            backgroundColor: getCellStyle("backgroundColor", props.metadata, filteredColumnSettings),
            fontStyle: getCellStyle("fontStyle", props.metadata, filteredColumnSettings),
            textDecoration: getCellStyle("textDecoration", props.metadata, filteredColumnSettings),
            justifyContent: getCellStyle("justifyContent", props.metadata, filteredColumnSettings),
          },
        },
      ];
    } else {
      return null;
    }
  };

  const inPlaceDataTypeConversion = (value, dataType, decimal) => {
    if (dataType != null && dataType != "") {
      return eval(props.convertDataTypes(dataType, decimal));
    } else {
      return value;
    }
  };

  const getGroupSummaryReducer = () => {
    if (datasource.sourceType == "postgres") {
      return genericPostgresGridReducer;
    }

    if (
      props.metadata != null &&
      props.metadata.columnGrouping != null &&
      props.metadata.columnGrouping.field != null &&
      props.metadata.columnGrouping.field.length > 0 &&
      props.metadata.columnPivotColumns != null &&
      props.metadata.columnPivotColumns.field != null &&
      props.metadata.columnPivotColumns.field.length > 0
    ) {
      if (props.metadata.columnPivotsAggregators != null && props.metadata.columnPivotsAggregators.length > 0) {
        switch (props.metadata.columnPivotsAggregators[0].aggregator) {
          case "count":
            return countGridReducer;
          case "sum":
            return sumGridReducer;
          case "avg":
            return avgGridReducer;
          default:
            return countGridReducer;
        }
      } else {
        return countGridReducer;
      }
    } else {
      return countGridReducer;
    }
  };

  const getSummaryReducerInitialValueObj = () => {
    let footerRow = {};
    if (tableColumns != null && tableColumns.length > 0) {
      tableColumns.forEach((tableColumnDetails) => {
        if (tableColumnDetails.columnTotalFunction != "max" && tableColumnDetails.columnTotalFunction != "min") {
          footerRow[tableColumnDetails.name] = 0;
        }
        if (tableColumnDetails.columnTotalFunction == "count_dist") {
          footerRow[tableColumnDetails.name] = [];
        }
      });
    }
    return footerRow;
  };

  const summaryReducer = {
    initialValue: getSummaryReducerInitialValueObj(),
    reducer: (accumulator, item) => {
      tableColumns.forEach((tableColumnDetails) => {
        if (tableColumnDetails.columnTotalFunction) {
          if (!isNaN(Number(item[tableColumnDetails.name]))) {
            if (tableColumnDetails.columnTotalFunction == "sum" || tableColumnDetails.columnTotalFunction == "avg") {
              accumulator[tableColumnDetails.name] += Number(item[tableColumnDetails.name]);
            }
            if (tableColumnDetails.columnTotalFunction == "max") {
              accumulator[tableColumnDetails.name] = accumulator[tableColumnDetails.name]
                ? Math.max(accumulator[tableColumnDetails.name], item[tableColumnDetails.name])
                : item[tableColumnDetails.name];
            }
            if (tableColumnDetails.columnTotalFunction == "min") {
              accumulator[tableColumnDetails.name] = accumulator[tableColumnDetails.name]
                ? Math.min(accumulator[tableColumnDetails.name], item[tableColumnDetails.name])
                : item[tableColumnDetails.name];
            }
          }

          if (tableColumnDetails.columnTotalFunction == "count") {
            accumulator[tableColumnDetails.name] += 1;
          }
          if (tableColumnDetails.columnTotalFunction == "count_dist") {
            if (
              accumulator[tableColumnDetails.name] != null &&
              typeof accumulator[tableColumnDetails.name] == "object" &&
              accumulator[tableColumnDetails.name].indexOf(item[tableColumnDetails.name]) == -1
            ) {
              accumulator[tableColumnDetails.name].push(item[tableColumnDetails.name]);
            }
          }
        }
      });

      return accumulator;
    },
    complete: (accumulator, arr) => {
      tableColumns.forEach((tableColumnDetails) => {
        if (tableColumnDetails.columnTotalFunction) {
          if (tableColumnDetails.columnTotalFunction == "avg") {
            accumulator[tableColumnDetails.name] = (
              Math.round((accumulator[tableColumnDetails.name] * 100) / arr.length) / 100
            ).toFixed(2);
          }
          if (tableColumnDetails.columnTotalFunction == "count_dist") {
            accumulator[tableColumnDetails.name] = accumulator[tableColumnDetails.name].length;
          }
        }
      });

      return accumulator;
    },
  };

  const summaryGridReducer = {
    initialValue: {},
    reducer: (accumulator, item) => {
      if (
        enablePivot &&
        props.metadata.columnPivotsAggregators != null &&
        props.metadata.columnPivotsAggregators.length > 0 &&
        props.metadata?.pivotAggregatorField
      ) {
        if (accumulator.accFields == null) {
          accumulator.accFields = [];
        }
        if (accumulator[item[pivot[0]]] == null) {
          accumulator[item[pivot[0]]] = 0;
          accumulator.accFields.push(item[pivot[0]]);
        }
        let aggregator = props.metadata.columnPivotsAggregators[0].aggregator;
        if (aggregator == "count") {
          accumulator[item[pivot[0]]] += 1;
        } else if (aggregator == "sum" || aggregator == "avg") {
          let parsedPivotAggregatorField =
            props.metadata.pivotAggregatorField.indexOf(".") != -1
              ? props.metadata.pivotAggregatorField.split(".")[1]
              : props.metadata.pivotAggregatorField;
          if (!isNaN(Number(item[parsedPivotAggregatorField]))) {
            if (accumulator[item[pivot[0]] + "AVGCountValues"] == null) {
              accumulator[item[pivot[0]] + "AVGCountValues"] = 0;
            }
            accumulator[item[pivot[0]] + "AVGCountValues"] += 1;
          }
          accumulator[item[pivot[0]]] += Number(item[parsedPivotAggregatorField]);
        } else {
          accumulator[item[pivot[0]]] += 1;
        }
      } else {
        if (accumulator[item[pivot[0]]] == null) {
          accumulator[item[pivot[0]]] = 0;
        }
        accumulator[item[pivot[0]]] += 1;
      }

      return accumulator;
    },
    complete: (accumulator, arr) => {
      if (
        enablePivot &&
        props.metadata.columnPivotsAggregators != null &&
        props.metadata.columnPivotsAggregators.length > 0 &&
        props.metadata?.pivotAggregatorField
      ) {
        let aggregator = props.metadata.columnPivotsAggregators[0].aggregator;
        if (aggregator == "avg") {
          accumulator.accFields.forEach((el) => {
            accumulator[el] = Math.round((accumulator[el] / accumulator[el + "AVGCountValues"]) * 10) / 10;
          });
        }
      }

      return accumulator;
    },
  };

  const getDefaultSortInfo = () => {
    let newDefaultSortInfo = [];
    if (props.metadata && props.metadata.columnSettings && props.metadata.columnSettings.length > 0) {
      props.metadata.columnSettings.forEach((columnSettings) => {
        if (columnSettings.sort) {
          newDefaultSortInfo.push({
            name:
              columnSettings.column.indexOf(".") != -1 ? columnSettings.column.split(".")[1] : columnSettings.column,
            dir: columnSettings.sort == "asc" ? 1 : -1,
          });
        }
      });
    }
    return newDefaultSortInfo;
  };

  const onRenderRow = (rowProps) => {
    /* if (props.metadata.rowAggregators && props.metadata.rowAggregators.field && props.metadata.rowAggregators.field.length > 0) {
      let total;
      if (props.metadata.rowAggregators.aggregator == "sum" || props.metadata.rowAggregators.aggregator == "avg") {
        total = 0;
        props.metadata.rowAggregators.field.forEach((field) => (total += Number(rowProps.data[field.split(".")[1]])));
      }
      if (props.metadata.rowAggregators.aggregator == "max") {
        props.metadata.rowAggregators.field.forEach(
          (field) =>
            (total = total
              ? Math.max(total, Number(rowProps.data[field.split(".")[1]]))
              : Number(rowProps.data[field.split(".")[1]]))
        );
      }
      if (props.metadata.rowAggregators.aggregator == "min") {
        props.metadata.rowAggregators.field.forEach(
          (field) =>
            (total = total
              ? Math.min(total, Number(rowProps.data[field.split(".")[1]]))
              : Number(rowProps.data[field.split(".")[1]]))
        );
      }
      if (props.metadata.rowAggregators.aggregator == "min") {
        props.metadata.rowAggregators.field.forEach(
          (field) =>
            (total = total
              ? Math.min(total, Number(rowProps.data[field.split(".")[1]]))
              : Number(rowProps.data[field.split(".")[1]]))
        );
      }
      if (props.metadata.rowAggregators.aggregator == "avg") {
        total = Math.round((total * 100) / props.metadata.rowAggregators.field.length) / 100;
      }
      rowProps.data.total = total;
    } */
  };

  const gridStyle = { height: "100%", minHeight: "auto" };

  const onColumnResize = useCallback(
    ({ column, flex, width }) => {
      const newColumns = tableColumns.map((c) => {
        if (c.name === column.name) {
          c = Object.assign({}, c, { width, flex });
        }
        return c;
      });
    },
    [tableColumns]
  );

  const renderGroupValue = useCallback(
    ({ value, groupSummary }) => (
      <span
        className={getCellPropsClassNames(null, props.metadata)}
        style={{
          fontSize: getCellStyle("fontSize", props.metadata, null),
          fontFamily: getCellStyle("fontFamily", props.metadata, null),
          color: getCellStyle("color", props.metadata, null),
          backgroundColor: getCellStyle("backgroundColor", props.metadata, null),
          fontStyle: getCellStyle("fontStyle", props.metadata, null),
          textDecoration: getCellStyle("textDecoration", props.metadata, null),
        }}
      >
        {value}
        {/* {value} ({groupSummary} registos) */}
      </span>
    ),
    [props.metadata]
  );

  const countReducer = {
    initialValue: 0,
    reducer: (v: number) => v + 1,
  };

  const countGridReducer = {
    initialValue: {
      count: 0,
      grandTotal: 0,
    },
    reducer: (v: { count: number, grandTotal: number }, item) => {
      return {
        count: v.count + 1,
        grandTotal: v.grandTotal + 1,
      };
    },
  };

  const sumReducer = {
    initialValue: 0,
    reducer: (a: number, b: number) => Number(a) + Number(b),
  };

  const sumGridReducer = {
    initialValue: 0,
    reducer: (acc: number, item: any) => {
      if (props.metadata?.pivotAggregatorField) {
        let parsedPivotAggregatorField =
          props.metadata.pivotAggregatorField.indexOf(".") != -1
            ? props.metadata.pivotAggregatorField.split(".")[1]
            : props.metadata.pivotAggregatorField;
        if (!isNaN(Number(item[parsedPivotAggregatorField]))) {
          return acc + Number(item[parsedPivotAggregatorField]);
        }
      }
      return acc;
    },
    complete: (accumulatedValue) => {
      return { grandTotal: accumulatedValue };
    },
  };

  const avgReducer = {
    initialValue: 0,
    reducer: (a: number, b: number) => Number(a) + Number(b),
    complete: (accumulatedValue: number, array: array) => {
      return Math.round((accumulatedValue / array.length) * 10) / 10;
    },
  };

  const avgGridReducer = {
    initialValue: 0,
    reducer: (acc: number, item: any) => {
      if (props.metadata?.pivotAggregatorField) {
        // TODO AV: in the reducer, return the result of the new backend query for this particular line (check item)
        // in case of mongo, the current reducers are needed and should not be touched
        // in case of postgres, we need to create a new generic reducer that simply gets the values from the new backend queries
        let parsedPivotAggregatorField =
          props.metadata.pivotAggregatorField.indexOf(".") != -1
            ? props.metadata.pivotAggregatorField.split(".")[1]
            : props.metadata.pivotAggregatorField;
        if (!isNaN(Number(item[parsedPivotAggregatorField]))) {
          return acc + Number(item[parsedPivotAggregatorField]);
        }
      }
      return acc;
    },
    complete: (accumulatedValue: number, array: array) => {
      return { grandTotal: Math.round((accumulatedValue / array.length) * 10) / 10 };
    },
  };

  const genericPostgresGridReducer = {
    initialValue: 0,
    reducer: (acc: number, item: any) => {
      if (acc !== 0) {
        return acc;
      }

      try {
        const columnGroupingWithoutTable = props.metadata.columnGrouping.field.map((el) => el.split(".")[1]);
        const aggregatorField = props.metadata.pivotAggregatorField.replace(".", "_");
        const lineGroupKeys = Object.keys(pivotLineGroups[0]);
        const lineGroupAggregatorField = lineGroupKeys.filter((key) => key.indexOf(aggregatorField) != -1)[0];
        const lineGroupSelectKeys = lineGroupKeys.filter((key) => {
          if (typeof columnGroupingWithoutTable === "string") {
            return key.indexOf(columnGroupingWithoutTable) != -1;
          } else {
            let found = false;
            columnGroupingWithoutTable.forEach((c) => {
              if (key.indexOf(c) != -1) {
                found = true;
              }
            });
            return found;
          }
        });

        return parseFloat(
          pivotLineGroups.filter((p) => {
            const found = lineGroupSelectKeys
              .map((key) => {
                const itemKey = Object.keys(item).filter((k) => k.indexOf(key) != -1)[0];
                if (p[key] == item[itemKey]) {
                  return true;
                } else {
                  return false;
                }
              })
              .reduce((a, c) => a && c, true);

            return found;
          })[0][lineGroupAggregatorField]
        );
      } catch (e) {
        return acc;
      }
    },
    complete: (accumulatedValue: number, array: array) => {
      let filteredColumnSettings;
      if (props.metadata.columnSettings != null && props.metadata.pivotAggregatorField != null) {
        filteredColumnSettings = props.metadata.columnSettings.filter(
          (el) => el.column == props.metadata.pivotAggregatorField
        );
        filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
      }
      if (
        filteredColumnSettings != null &&
        filteredColumnSettings.suffix != null &&
        filteredColumnSettings.suffix != "" &&
        accumulatedValue.toString().indexOf(filteredColumnSettings.suffix) == -1
      ) {
        return { grandTotal: accumulatedValue + filteredColumnSettings.suffix };
      } else {
        return { grandTotal: accumulatedValue };
      }
    },
  };

  function isMeasure(v) {
    if (v != null && typeof v === "object") {
      return true;
    } else {
      return false;
    }
  }

  const genericPostgresReducer = {
    initialValue: 0,
    reducer: (a: number, b: number) => {
      return b;
    },
    complete: (accumulatedValue: number, array: array) => {
      return accumulatedValue;
    },
  };

  const [enablePivot, setEnablePivot] = useState(false);
  const [pivot, setPivot] = useState([]);
  const [groupBy, setGroupBy] = useState([]);
  const [columnGroups, setColumnGroups] = useState({});
  const [pivotColumnGroups, setPivotColumnGroups] = useState([]);
  const [pivotLineGroups, setPivotLineGroups] = useState([]);
  const [dropdownsWithSelectedOptionsGridEmptyText, setDropdownsWithSelectedOptionsGridEmptyText] = useState(null);

  const [columnGroupsCache, setColumnGroupsCache] = useState({});
  const [pivotColumnGroupsCache, setPivotColumnGroupsCache] = useState({});
  const [pivotLineGroupsCache, setPivotLineGroupsCache] = useState({});

  const loadData = ({ skip, sortInfo, limit }) => {
    return props
      .getTableData(
        props.metadata.unlimited ? 0 : skip,
        props.metadata.unlimited ? 999999999 : limit,
        props.metadata,
        layout,
        pages,
        report
      )
      .then((data) => {
        const valuesAsColumns = props.metadata.values.map((v) => {
          if (datasource.sourceType === "postgres") {
            if (typeof v.field === "string") {
              return (
                (v.aggregator && v.aggregator.length ? v.aggregator + "_" : "") +
                props.getSourceByDataMode(v.field.replace(".", "_"))
              );
            } else {
              return (v.aggregator && v.aggregator.length ? v.aggregator + "_" : "") + v.field.name;
            }
          } else if (datasource.sourceType === "mongo") {
            return v.field.split(".")[1];
          } else {
            console.error("unkown datasource type");
            return "";
          }
        });

        // COLUMN GROUPS
        if (data.columnGroups && data.columnGroups.length) {
          Object.keys(data.columnGroups[0]).forEach((cg) => {
            let firstUnderscore = 0;
            if (cg.indexOf("count_dist_") === 0) {
              firstUnderscore = "count_dist_".length;
            } else {
              firstUnderscore = cg.indexOf("_") + 1;
            }
            const column = cg.substring(firstUnderscore);

            props.metadata.values
              .filter((v) => {
                if (!isMeasure(v.field)) {
                  return props.getSourceByDataMode(v.field.replace(".", "_")).indexOf(column) != -1;
                } else if (isMeasure(v.field)) {
                  return v.field.name === column;
                }
              })
              .forEach((v) => {
                if (v.dataType && v.decimalPlaces && v.dataType != "" && v.decimalPlaces != "") {
                  const value = data.columnGroups[0][cg];
                  data.columnGroups[0][cg] = eval(props.convertDataTypes(v.dataType, v.decimalPlaces));
                }
              });
          });
        }

        let newColumnGroups = {};
        if (data.columnGroups && data.columnGroups.length) {
          newColumnGroups = JSON.parse(JSON.stringify(data.columnGroups[0]));
          props.metadata.values.forEach((v) => {
            let columnGroupField =
              (v.aggregator && v.aggregator.length ? v.aggregator + "_" : "") +
              props.getSourceByDataMode((typeof v.field == "object" ? v.field.name : v.field).replace(".", "_"));
            let filteredColumnSettings;
            if (props.metadata.columnSettings != null) {
              filteredColumnSettings = props.metadata.columnSettings.filter(
                (el) => el.column == props.getSourceByDataMode(typeof v.field == "object" ? v.field.name : v.field)
              );
              filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
            }
            let filteredColumnGroup;
            filteredColumnGroup = Object.keys(newColumnGroups).filter(
              (el) =>
                el.indexOf(
                  props.getSourceByDataMode(typeof v.field == "object" ? v.field.name : v.field).replace(/\./g, "_")
                ) != -1
            );
            filteredColumnGroup = filteredColumnGroup.length > 0 ? filteredColumnGroup[0] : null;
            if (
              filteredColumnGroup != null &&
              filteredColumnSettings != null &&
              filteredColumnSettings.suffix != null &&
              filteredColumnSettings.suffix != "" &&
              newColumnGroups[filteredColumnGroup].indexOf(filteredColumnSettings.suffix) == -1
            ) {
              newColumnGroups[filteredColumnGroup] =
                newColumnGroups[filteredColumnGroup] + filteredColumnSettings.suffix;
            } else {
              newColumnGroups[filteredColumnGroup] = newColumnGroups[filteredColumnGroup];
            }
          });
        }

        if (
          data.columnGroups &&
          data.columnGroups.length &&
          JSON.stringify(newColumnGroups) !== JSON.stringify(columnGroupsCache)
        ) {
          setColumnGroupsCache(newColumnGroups);
          setColumnGroups(newColumnGroups);
        }

        // PIVOT COLUM GROUPS
        if (data.pivotColumnGroups && data.pivotColumnGroups.length) {
          data.pivotColumnGroups.forEach((d) => {
            const columns = Object.keys(d);
            columns.forEach((c) => {
              const firstUnderscore = c.indexOf("_") + 1;
              const column = c.substring(firstUnderscore);
              const valueIndex = valuesAsColumns.findIndex((vac) => vac.indexOf(column) != -1);
              const val = props.metadata.values[valueIndex];

              const value = d[c];
              if (
                value != null &&
                value != undefined &&
                typeof value != "object" &&
                typeof value != "array" &&
                val.dataType &&
                val.dataType.length
              ) {
                d[c] = eval(props.convertDataTypes(val.dataType, val.decimalPlaces));
              }
            });
          });
        }
        let newPivotColumnGroups = {};
        if (data.pivotColumnGroups && data.pivotColumnGroups.length) {
          newPivotColumnGroups = JSON.parse(JSON.stringify(data.pivotColumnGroups));
          props.metadata.values.forEach((v) => {
            let columnGroupField =
              (v.aggregator && v.aggregator.length ? v.aggregator + "_" : "") +
              props.getSourceByDataMode((typeof v.field == "object" ? v.field.name : v.field).replace(".", "_"));
            if (typeof v.field == "object") {
              if (newPivotColumnGroups[0] != null && Object.keys(newPivotColumnGroups[0]).length) {
                Object.keys(newPivotColumnGroups[0]).forEach((key) => {
                  if (key.indexOf(columnGroupField) != -1) {
                    columnGroupField = key;
                  }
                });
              }
            }
            let filteredColumnSettings;
            if (props.metadata.columnSettings != null) {
              filteredColumnSettings = props.metadata.columnSettings.filter(
                (el) => el.column == (typeof v.field == "object" ? v.field.name : v.field)
              );
              filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
            }
            let filteredColumnGroup = [];
            filteredColumnGroup = newPivotColumnGroups.filter(
              (el) => el[columnGroupField] != null && el[columnGroupField] != ""
            );
            if (
              filteredColumnGroup.length &&
              filteredColumnSettings != null &&
              filteredColumnSettings.suffix != null &&
              filteredColumnSettings.suffix != "" &&
              (props.metadata.pivotAggregatorField == null ||
                props.metadata.pivotAggregatorField ==
                  props.getSourceByDataMode(typeof v.field == "object" ? v.field.name : v.field))
            ) {
              newPivotColumnGroups.forEach((el) => {
                if (el[columnGroupField].indexOf(filteredColumnSettings.suffix) == -1) {
                  el[columnGroupField] = el[columnGroupField] + filteredColumnSettings.suffix;
                }
              });
            }
          });
        }
        if (
          data.pivotColumnGroups &&
          data.pivotColumnGroups.length &&
          JSON.stringify(newPivotColumnGroups) !== JSON.stringify(pivotColumnGroupsCache)
        ) {
          setPivotColumnGroupsCache(newPivotColumnGroups);
          setPivotColumnGroups(newPivotColumnGroups);
        }

        // PIVOT LINE GROUPS
        if (data.pivotLineGroups && data.pivotLineGroups.length) {
          data.pivotLineGroups.forEach((d) => {
            const columns = Object.keys(d);
            columns.forEach((c) => {
              const firstUnderscore = c.indexOf("_") + 1;
              const column = c.substring(firstUnderscore);
              const valueIndex = valuesAsColumns.findIndex((vac) => vac.indexOf(column) != -1);
              const val = props.metadata.values[valueIndex];

              const value = d[c];
              if (
                value != null &&
                value != undefined &&
                typeof value != "object" &&
                typeof value != "array" &&
                val.dataType &&
                val.dataType.length
              ) {
                d[c] = eval(props.convertDataTypes(val.dataType, val.decimalPlaces));
              }
            });
          });
        }
        let newPivotLineGroups = {};
        if (data.pivotLineGroups && data.pivotLineGroups.length) {
          newPivotLineGroups = JSON.parse(JSON.stringify(data.pivotLineGroups));
          props.metadata.values.forEach((v) => {
            let columnGroupField =
              (v.aggregator && v.aggregator.length ? v.aggregator + "_" : "") +
              props.getSourceByDataMode((typeof v.field == "object" ? v.field.name : v.field).replace(".", "_"));
            let filteredColumnSettings;
            if (props.metadata.columnSettings != null) {
              filteredColumnSettings = props.metadata.columnSettings.filter(
                (el) => el.column == (typeof v.field == "object" ? v.field.name : v.field)
              );
              filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
            }
            let filteredColumnGroup = [];
            filteredColumnGroup = newPivotLineGroups.filter(
              (el) => el[columnGroupField] != null && el[columnGroupField] != ""
            );
            if (
              filteredColumnGroup.length &&
              filteredColumnSettings != null &&
              filteredColumnSettings.suffix != null &&
              filteredColumnSettings.suffix != "" &&
              (props.metadata.pivotAggregatorField == null ||
                props.metadata.pivotAggregatorField == (typeof v.field == "object" ? v.field.name : v.field))
            ) {
              newPivotLineGroups.forEach((el) => {
                if (el[columnGroupField].indexOf(filteredColumnSettings.suffix) == -1) {
                  el[columnGroupField] = el[columnGroupField] + filteredColumnSettings.suffix;
                }
              });
            }
          });
        }
        if (
          data.pivotLineGroups &&
          data.pivotLineGroups.length &&
          JSON.stringify(newPivotLineGroups) !== JSON.stringify(pivotLineGroupsCache)
        ) {
          setPivotLineGroupsCache(newPivotLineGroups);
          setPivotLineGroups(newPivotLineGroups);
        }
        // SELECT
        let tempData = data.data.map((d) => {
          return { ...d };
        });

        tempData.forEach((d) => {
          const columns = Object.keys(d);
          columns.forEach((c) => {
            const valueIndex = valuesAsColumns.indexOf(c);

            if (valueIndex > -1) {
              const val = props.metadata.values[valueIndex];

              const value = d[c];
              if (
                value != null &&
                value != undefined &&
                typeof value != "object" &&
                typeof value != "array" &&
                val.dataType &&
                val.dataType.length
              ) {
                d[c] = eval(props.convertDataTypes(val.dataType, val.decimalPlaces));
              }
            }
          });
        });

        let sortedData = sortAndSetTableData(getDefaultSortInfo(), tempData);

        props.checkPivotTableWrappers(sortedData);

        return { data: sortedData, count: data.count };
      })
      .finally(() => {
        //Workaround to delete double header column when pivot is active. InovuaReactDataGrid__header-group-cells doesnt include the grouping column
        if (
          props.metadata.columnGrouping != null &&
          props.metadata.columnGrouping.field != null &&
          props.metadata.columnGrouping.field.length > 0 &&
          props.metadata.columnPivotColumns != null &&
          props.metadata.columnPivotColumns.field != null &&
          props.metadata.columnPivotColumns.field.length > 0
        ) {
          setTimeout(() => {
            if (
              !props.metadata.showPivotCellsHeader &&
              document.getElementsByClassName(
                "InovuaReactDataGrid__column-header__content InovuaReactDataGrid__box--ellipsis"
              ).length > 0 &&
              document.getElementsByClassName(props.index).length > 0
            ) {
              Array.prototype.forEach.call(
                document
                  .getElementsByClassName(props.index)[0]
                  .getElementsByClassName(
                    "InovuaReactDataGrid__column-header__content InovuaReactDataGrid__box--ellipsis"
                  ),
                function (el) {
                  if (
                    (el.textContent != null && el.textContent == "Grupo") ||
                    (el.offsetParent != null &&
                      el.offsetParent.className.indexOf("InovuaReactDataGrid__column-header--has-group") != -1)
                  )
                    el.style.display = "none";
                }
              );
            } else {
              if (document.getElementsByClassName(props.index).length > 0) {
                Array.prototype.forEach.call(
                  document
                    .getElementsByClassName(props.index)[0]
                    .getElementsByClassName(
                      "InovuaReactDataGrid__column-header__content InovuaReactDataGrid__box--ellipsis"
                    ),
                  function (el) {
                    el.style.display = "block";
                  }
                );
              }
            }

            if (document.getElementsByClassName("InovuaReactDataGrid__header-group").length > 0) {
              Array.prototype.forEach.call(
                document.getElementsByClassName("InovuaReactDataGrid__header-group"),
                function (el) {
                  /* props.metadata.headerFontBold ? el.classList.add("boldFontWeight") : el.classList.add("normalFontWeight"); */
                  el.style.fontSize = props.metadata.headerFontSize ? props.metadata.headerFontSize + "px" : "12px";
                  el.style.fontFamily = props.metadata.headerFontFamily
                    ? props.metadata.headerFontFamily
                    : "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
                  el.style.borderRight = "1px solid white";
                  el.style.borderLeft = "1px solid white";
                  el.style.backgroundColor = "rgb(232, 232, 232)";
                  el.style.fontStyle = props.metadata.headerFontItalic ? "italic" : "initial";
                  el.style.textDecoration = props.metadata.headerFontUnderline ? "underline" : "none";
                  el.style.textAlign = "center";
                }
              );
            }
          }, 1500);
        }
      })
      .catch((err) => {
        return { data: [], count: 0 };
      });
  };

  // Check if it is necessary to select some dropdown options before getting data
  const checkMinimumSelectedDropdownOptions = () => {
    let filteredColumnSettings;

    // Get minimum fields necessary by column from the metadata column settings
    if (props.metadata.columnSettings != null) {
      filteredColumnSettings = props.metadata.columnSettings.filter(
        (el) => el.dropdownMinimumSelectedOptions != null && !isNaN(Number(el.dropdownMinimumSelectedOptions))
      );
    }
    if (filteredColumnSettings != null && filteredColumnSettings.length > 0) {
      let check = false;

      // Get dropdown values
      let dropdownsWithSelectedOptions = layout
        .filter((l) => l.kpiType === "dropdown" && l.i != props.index)
        .filter((l) => l.metadata !== undefined)
        .filter((l) => l.metadata.values && l.metadata.values.length && l.metadata.axis && l.metadata.axis.length)
        .map((dropdown) => {
          let source = dropdown.metadata.axis[0].split(".")[0];
          let field = dropdown.metadata.axis[0].split(".")[1];
          return {
            source: source,
            field: field,
            operator: "eq",
            value: dropdown.metadata.values.map((v) => v.value),
          };
        });
      if (dropdownsWithSelectedOptions.length > 0) {
        // Create grid empty text when necessary with dropdown fields to fill
        let gridEmptyText = "";
        filteredColumnSettings.forEach((columnSetting) => {
          if (gridEmptyText != "") {
            gridEmptyText += ", ";
          }
          gridEmptyText +=
            getCustomHeader(columnSetting.column.split(".")[0], columnSetting.column.split(".")[1]) +
            " (" +
            columnSetting.dropdownMinimumSelectedOptions +
            " opções)";
        });
        if (
          dropdownsWithSelectedOptionsGridEmptyText == null ||
          dropdownsWithSelectedOptionsGridEmptyText != gridEmptyText
        ) {
          setDropdownsWithSelectedOptionsGridEmptyText(gridEmptyText);
        }
        for (let i = 0; i < filteredColumnSettings.length; i++) {
          let columnSetting = filteredColumnSettings[i];
          let filteredDropdownsWithSelectedOptions = dropdownsWithSelectedOptions.filter(
            (el) => el.source + "." + el.field == columnSetting.column
          );
          if (filteredDropdownsWithSelectedOptions.length > 0) {
            filteredDropdownsWithSelectedOptions = JSON.parse(JSON.stringify(filteredDropdownsWithSelectedOptions[0]));

            // Compare the number of options selected by dropdown with the minimum necessary
            if (filteredDropdownsWithSelectedOptions.value.length >= columnSetting.dropdownMinimumSelectedOptions) {
              check = true;
            } else {
              check = false;
              break;
            }
          } else {
            check = false;
            break;
          }
        }
        if (!check) {
          return false;
        } else {
          return true;
        }
      } else {
        setDropdownsWithSelectedOptionsGridEmptyText(null);
        return false;
      }
    }
    return true;
  };

  // Check if in pivot mode
  const checkPivotMode = () => {
    if (props.metadata.tableMode != null && props.metadata.tableMode == "pivot") {
      if (
        props.metadata.columnGrouping != null &&
        props.metadata.columnGrouping?.field?.length > 0 &&
        props.metadata.columnPivotColumns != null &&
        props.metadata.columnPivotColumns?.field?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const getPivotGrandSummaryColumn = () => {
    if (props.metadata.pivotAggregatorField != null && props.metadata.pivotAggregatorField != []) {
      let filteredColumnSettings;
      if (props.metadata.columnSettings != null && props.metadata.pivotAggregatorField != null) {
        filteredColumnSettings = props.metadata.columnSettings.filter(
          (el) => el.column == props.metadata.pivotAggregatorField
        );
        filteredColumnSettings = filteredColumnSettings.length > 0 ? filteredColumnSettings[0] : null;
      }
      return {
        header: "Total",
        defaultFlex: props.metadata.lineTotalsWidth ? 0 : 1,
        width: props.metadata.lineTotalsWidth ? Number(props.metadata.lineTotalsWidth) : null,
        style: {
          fontSize: getCellStyle("fontSize", props.metadata, filteredColumnSettings),
          color: getCellStyle("color", props.metadata, filteredColumnSettings),
          backgroundColor: getCellStyle("backgroundColor", props.metadata, filteredColumnSettings),
          fontStyle: getCellStyle("fontStyle", props.metadata, filteredColumnSettings),
          textDecoration: getCellStyle("textDecoration", props.metadata, filteredColumnSettings),
          textAlign: getCellStyle("textAlign", props.metadata, filteredColumnSettings),
        },
        render: ({ data }) => {
          if (datasource.sourceType === "mongo" || data.depth === props.metadata?.columnGrouping?.field?.length) {
            return <b>{data.groupSummary.grandTotal}</b>;
          }
        },
      };
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      {tableColumns != null && tableColumns.length > 0 ? (
        <ReactDataGrid
          licenseKey={process.env.REACT_APP_DATAGRID_KEY}
          pagination={
            (enablePivot ||
              props.metadata.columnGrouping != null ||
              props.metadata.columnGrouping?.field?.length == 0 ||
              datasource.sourceType != "mongo") &&
            (props.metadata.unlimited == null || props.metadata.unlimited == false)
          }
          livePagination={
            (enablePivot ||
              props.metadata.columnGrouping != null ||
              props.metadata.columnGrouping?.field?.length == 0 ||
              datasource.sourceType != "mongo") &&
            (props.metadata.unlimited == null || props.metadata.unlimited == false)
          }
          idProperty="id"
          columns={tableColumns}
          dataSource={checkMinimumSelectedDropdownOptions() && checkPivotMode() ? loadData : []}
          emptyText={
            checkPivotMode()
              ? checkMinimumSelectedDropdownOptions()
                ? "Sem registos disponíveis"
                : "Preencha os seguintes filtros para visualizar os registos: " +
                  dropdownsWithSelectedOptionsGridEmptyText
              : "Preencha os campos 'Table grouping columns' e 'Table pivot columns' para apresentar dados"
          }
          style={gridStyle}
          headerHeight={30}
          rowHeight={30}
          showColumnMenuTool={false}
          rowStyle={rowStyle}
          enableColumnAutosize={true}
          onReady={() => {
            setGroupingAndPivotColumns();
          }}
          footerRows={((c) => footerRows(c))()}
          summaryReducer={props.metadata?.pivotAggregatorField != null ? summaryGridReducer : summaryReducer}
          defaultSortInfo={defaultSortInfo}
          onRenderRow={onRenderRow}
          pivot={enablePivot ? pivot : null}
          groupBy={groupBy}
          groupNestingSize={60}
          onGroupByChange={setGroupBy}
          groupSummaryReducer={getGroupSummaryReducer()}
          showPivotSummaryColumns={true}
          pivotGrandSummaryColumn={getPivotGrandSummaryColumn()}
          onColumnResize={onColumnResize}
          groupColumn={{
            header:
              props.metadata.headerGroupingTitle != null && props.metadata.headerGroupingTitle != ""
                ? props.metadata.headerGroupingTitle
                : "Grupo",
            defaultFlex: props.metadata.headerGroupingWidth ? 0 : 1,
            width: props.metadata.headerGroupingWidth ? Number(props.metadata.headerGroupingWidth) : null,
            renderGroupValue,
            headerProps: {
              className: props.metadata.headerFontBold ? "boldFontWeight" : "normalFontWeight",
              style: {
                fontSize: props.metadata.headerFontSize ? props.metadata.headerFontSize + "px" : "12px",
                fontFamily: props.metadata.headerFontFamily
                  ? props.metadata.headerFontFamily
                  : "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                color: props.metadata.headerTextColor ? props.metadata.headerTextColor : null,
                /* backgroundColor: props.metadata.headerBackgroundColor ? props.metadata.headerBackgroundColor : null,
                 */
                /* borderRight: "1px solid white",
                borderLeft: "1px solid white", */
                fontStyle: props.metadata.headerFontItalic ? "italic" : "initial",
                textDecoration: props.metadata.headerFontUnderline ? "underline" : "none",
              },
            },
            headerAlign: "center",
          }}
        />
      ) : null}
    </React.Fragment>
  );
}
