import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { v1 } from "uuid";
import classnames from "classnames";
import styled from "styled-components";
import { publishNotification } from "../actions/NotificationActions";
import { KSTKSelect } from "../components/KSTKSelect";
import KSTKReportSourcesEditor from "../components/KSTKReportSourcesEditor";
import KSTKEmptyContent from "../components/KSTKEmptyContent";
import KSTKReportEditor from "../components/KSTKReportEditor";
import { KSTKColorPalettePicker } from "../components/KSTKColorPalettePicker";
import { getDatasourceById, getDatasources } from "../services/datasource.service";
import { getOrganizations } from "../services/organization.service";
import { getClients, getClientFilterByInfo, getClientReportPermissions } from "../services/clients.service";
import {
  ORG,
  USER,
  REP_FILTERS,
  updateReport,
  getReportById,
  generateReport,
  publishReport,
  getReports,
  clearOrgDataCache,
} from "../services/report.service";
/* import { getCategories } from "../services/category.service"; */
import { getColumnsFromDatasource } from "../util/report.util";
import KSTKFilterField from "../components/KSTKFilterField";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => ({
  publishNotification: (metadata) => dispatch(publishNotification(metadata)),
});

const mapStateToProps = (state) => ({
  ...state,
});

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 100px 50px calc(100% - 150px);
  grid-template-columns: 100%;
`;

const ReportDetailTabContent = styled(TabContent)`
  height: calc(100% - 40px);
`;

const ReportDetailTabPane = styled(TabPane)`
  height: 100%;
`;

const ZoomPane = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px;
  cursor: pointer;
  z-index: 999;
`;

const AutoUpdateTimerPane = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px;
  z-index: 999;
`;

const TopControls = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  /* border-bottom: 1px solid lightgray; */

  button {
    width: 150px;
  }
`;

// const MetaForm = styled.form`
//   display: grid;
//   grid-template-columns: 100%;
//   grid-template-rows: 50% 50%;
// `;

const MetaForm = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 10px;

  & > input {
    width: 200px;
  }

  & > div {
    width: 200px;
  }
`;

const FormActions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const DevProdSwitch = styled(FormGroup)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  margin-left: 0px;
  font-size: 12px;
  .form-check-input {
    margin: 0px 10px;
  }
`;

const ReportControls = styled.div`
  display: grid;
  grid-template-columns: 90% 10%;
  grid-template-rows: 100%;
  padding: 10px 0;
`;

const SimulationForm = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  & > div {
    width: 100%;
  }
`;

const ReportSetupWrapper = styled.div`
  /* height: calc(100% - 200px); */
`;

const ReportSetup = styled.div`
  height: 100%;

  &.editMode {
    padding-top: 8px;
  }

  &.expanded {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`;

const ReportSetupNav = styled.div`
  &.viewMode {
    display: none;
  }
`;

const ReportNavLink = styled(NavLink)`
  cursor: pointer;
`;

const AlwaysPresentControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    display: flex;
    gap: 8px;
  }

  &.expanded {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin-top: 8px;
    margin-right: 8px;
  }

  &.viewMode {
    display: none;
  }
`;

// Meta report reports label custom component
const MultiValue = (props) => {
  let selectedOptionsString = [];
  props.selectProps.value.forEach((el) => {
    selectedOptionsString.push(el.label);
  });
  if (props.children === props.selectProps.value[0].label) {
    return <div>{"Meta reports (" + props.selectProps.value.length + ")"}</div>;
  } else {
    return "";
  }
};
const stopEventPropragation = (e) => {
  e.stopPropagation();
};

class Report extends Component {
  constructor(props) {
    super(props);
    this.toggleExpandCollapse = this.toggleExpandCollapse.bind(this);
    this.shouldFilterBy = this.shouldFilterBy.bind(this);
    this.simulateFilterBy = this.simulateFilterBy.bind(this);
    this.updateReport = this.updateReport.bind(this);
    this.publishReport = this.publishReport.bind(this);
    this.isContentEmpty = this.isContentEmpty.bind(this);
    this.onDatasourceChange = this.onDatasourceChange.bind(this);
    this.onReportEditorChange = this.onReportEditorChange.bind(this);
    this.onColorPaletteChange = this.onColorPaletteChange.bind(this);
    this.onGlobalFiltersChange = this.onGlobalFiltersChange.bind(this);
    this.getReportFromProps = this.getReportFromProps.bind(this);
    this.togglePublishingReportModal = this.togglePublishingReportModal.bind(this);
    this.togglePublishedReportModal = this.togglePublishedReportModal.bind(this);
    this.checkIfReportIsDirty = this.checkIfReportIsDirty.bind(this);
    this.switchDataMode = this.switchDataMode.bind(this);
    this.switchReportMode = this.switchReportMode.bind(this);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mode = window.self !== window.top ? "view" : urlParams.get("mode");
    const URLFilterDataBy = urlParams.get("filterDataBy");

    this.state = {
      activeTab: "1",
      report: [this.getReportFromProps()],
      newReportName: this.getReportFromProps().name,
      newReportDescription: this.getReportFromProps().description,
      newReportTags: this.getReportFromProps().tags,
      category: this.getReportFromProps().category,
      autoUpdateTimer: this.getReportFromProps().autoUpdateTimer,
      autoUpdateTimerOngoing: false,
      autoUpdateTimerFlag: true,
      autoUpdateTimerSeconds: null,
      selectedDatasourceId: this.getReportFromProps().datasourceId ? this.getReportFromProps().datasourceId : "",
      selectedDatasource: null,
      shouldFilterBy: this.getReportFromProps().filterBy ? this.getReportFromProps().filterBy : null,
      dataMode: "pro",
      simulateFilterBy: null,
      simulateFilterData: [],
      clientReportPermissions: null,
      tempReportPages: this.getReportFromProps().pages,
      colorPalette:
        this.getReportFromProps().colorPalette && this.getReportFromProps().colorPalette.length > 5
          ? this.getReportFromProps().colorPalette
          : ["#36A2EB", "#FF6384", "#4BC0C0", "#FF9F40", "#9966FF", "#FFCD56", "#C9CBCF", "0.6"],
      customHeaders: null,
      customModelations: null,
      filters: this.getReportFromProps().filters,
      mode: mode,
      URLFilterDataBy: URLFilterDataBy,
      isPublishingReport: false,
      isPublishingReportModalOpen: false,
      isPublishedReportModalOpen: false,
      isReportDirty: false,
      expandCollapseFlag: false,
      reportOriginalPagesSetup: false,
      isImportingReportModalOpen: false,
      reportToImport: null,
      metaReportReports: this.getReportFromProps().metaReportReports
        ? this.getReportFromProps().metaReportReports
        : null,
      metaReportPages: [],
    };

    this.sourcesEditorRef = React.createRef();
    this.reportEditorRef = React.createRef();
    this.reportSetupRef = React.createRef();
    this.alwaysPresentControlsRef = React.createRef();
    this.filterByRef = React.createRef();

    this.filters = REP_FILTERS;
    this.autoUpdateTimers = [
      { value: null, label: "Empty" },
      { value: "5 minutes", label: "5 minutes" },
      { value: "24 hours", label: "24 hours" },
      { value: "7 days", label: "7 days" },
      { value: "30 days", label: "30 days" },
    ];

    this.layout = [
      { i: "a", x: 0, y: 0, w: 1, h: 2, static: true },
      { i: "b", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
      { i: "c", x: 4, y: 0, w: 1, h: 2 },
    ];

    if (mode !== "view") {
      this.fetchDatasources(this.state.selectedDatasourceId);
    }
  }

  componentDidMount(prevProps, prevState) {
    this.fetchReports();
    /* this.fetchCategories(); */
    //Was inside the constructor and triggered the error "Invalid hook call"
    if (this.getReportFromProps().filterBy) {
      this.shouldFilterBy(this.getReportFromProps().filterBy);
    }
    //Setup rep filters structure for KSTKSelect
    const filters = [];
    this.filters.forEach((f) =>
      f != "" ? filters.push({ label: f, value: f }) : filters.push({ label: "Empty", value: null })
    );
    this.filters = filters;
    //Change UI according to mode in url params
    const mode = this.state.mode;
    const {
      match: { params },
    } = this.props;

    if (mode != null) {
      switch (mode) {
        case "view":
          this.setState({ mode: "view", activeTab: "1" });

          getReportById(params.id).then((rep) => {
            // this.props.report = [rep];

            this.setState({
              report: [rep],
              newReportName: rep.name,
              selectedDatasourceId: rep.datasourceId ? rep.datasourceId : "",
              shouldFilterBy: rep.filterBy ? rep.filterBy : null,
              tempReportPages: rep.layout,
              colorPalette:
                rep.colorPalette && rep.colorPalette.length > 5
                  ? rep.colorPalette
                  : ["#36A2EB", "#FF6384", "#4BC0C0", "#FF9F40", "#9966FF", "#FFCD56", "#C9CBCF", "0.6"],
            });

            if (this.state.URLFilterDataBy) {
              this.shouldFilterBy(this.state.URLFilterDataBy);
              this.simulateFilterBy(getClientFilterByInfo());
              this.clientReportPermissions(getClientReportPermissions(rep.id));
              //fetchAuthentication().isPublic ? null: this.clientReportPermissions(getClientReportPermissions(rep.id));
              //Force report to be in production mode when displayed as an iframe
              this.setState({
                dataMode: "pro",
              });
            } else if (rep.filterBy) {
              this.shouldFilterBy(rep.filterBy);
            }

            if (rep.datasourceId) {
              this.fetchDatasources(rep.datasourceId);
            }
          });

          break;
        case "edit":
          this.clientReportPermissions(getClientReportPermissions(this.state.report.id));
          this.setState({
            mode: "edit",
          });
          break;
        default:
          break;
      }
    }
    // Get the following fields added by the analytics platform from the urlParams
    /* const filterDataBy = urlParams.get("filterDataBy");
    console.log(filterDataBy);
    const filterDataValue = urlParams.get("filterDataValue");
    console.log(filterDataValue); */
  }

  fetchReports(id) {
    getReports().then((data) => {
      this.setState({
        reports: data,
      });
    });
  }

  getMetaReportPages() {
    if (
      this.state.reports != null &&
      this.state.reports.length &&
      this.state.metaReportReports != null &&
      this.state.metaReportPages.length == 0 &&
      this.state.simulateFilterBy != null
    ) {
      let metaReportPages = this.state.metaReportReports
        .map((r) => {
          let filteredReport = this.state.reports.filter((el) => el.id == r);
          if (filteredReport.length) {
            return filteredReport[0].pages;
          } else {
            return [];
          }
        })
        .flat();
      let metaReportPagesIDs = metaReportPages.map((r) => r.id);

      for (let i = 0; i < metaReportPages.length; i++) {
        const page = metaReportPages[i];
        if (metaReportPagesIDs.indexOf(page.id) != i) {
          metaReportPages[i].id = v1();
        }
      }

      this.setState({
        metaReportPages: metaReportPages,
      });
    }
  }

  setAutoUpdateTimer() {
    if (
      this.state.report != null &&
      this.state.report.length &&
      this.state.selectedDatasource != null &&
      this.state.simulateFilterBy != null &&
      this.state.autoUpdateTimer != null &&
      this.state.mode == "view"
    ) {
      if (this.state.autoUpdateTimerOngoing == false) {
        let timerSeconds = null;
        switch (this.state.autoUpdateTimer) {
          case "5 minutes":
            timerSeconds = 5 * 60;
            break;
          case "24 hours":
            timerSeconds = 24 * 60 * 60;
            break;
          case "7 days":
            timerSeconds = 7 * 24 * 60 * 60;
            break;
          case "30 days":
            timerSeconds = 30 * 24 * 60 * 60;
            break;
          default:
            break;
        }
        this.setState({
          autoUpdateTimerOngoing: true,
          autoUpdateTimerSeconds: timerSeconds,
        });
        setTimeout(() => {
          this.setState({
            autoUpdateTimerOngoing: false,
            autoUpdateTimerFlag: !this.state.autoUpdateTimerFlag,
          });
        }, timerSeconds * 1000);
      } else {
        setTimeout(() => {
          this.setState({
            autoUpdateTimerSeconds: this.state.autoUpdateTimerSeconds - 1,
          });
        }, 1000);
      }
    }
  }

  fetchDatasources(id) {
    getDatasourceById(id).then((data) => {
      let selectedDS = data;

      if (selectedDS != null) {
        this.setState({
          selectedDatasource: selectedDS,
          customHeaders: selectedDS.customHeaders,
          customModelations: selectedDS.customModelations,
        });
      }
    });
  }

  /* fetchCategories() {
    getCategories().then((data) => {
      this.setState({
        categories: data,
      });
    });
  } */

  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleExpandCollapse() {
    if (this.alwaysPresentControlsRef.current && this.reportSetupRef.current) {
      this.alwaysPresentControlsRef.current.classList.toggle("expanded");
      this.reportSetupRef.current.classList.toggle("expanded");
      this.setState({
        expandCollapseFlag: !this.state.expandCollapseFlag,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.newReportName != "Untitled report..." &&
      document.title != this.state.newReportName &&
      this.state.mode !== "view"
    ) {
      document.title = this.state.newReportName;
    }
    if (this.reportOriginalSavedState == null && this.state.report[0]?.id != null) {
      this.reportOriginalSavedState = JSON.stringify({
        name: this.state.newReportName,
        description: this.state.newReportDescription,
        tags: this.state.newReportTags,
        autoUpdateTimer: this.state.autoUpdateTimer,
        datasourceId: this.state.selectedDatasourceId,
        filterBy: this.state.shouldFilterBy,
        colorPalette: this.state.colorPalette,
        filters: this.state.filters,
        metaReportReports: this.state.metaReportReports,
      });
    }
    this.checkIfReportIsDirty();
    this.getMetaReportPages();
    if (
      this.state.autoUpdateTimerSeconds == null ||
      this.state.autoUpdateTimerSeconds == 0 ||
      prevState.autoUpdateTimerSeconds != this.state.autoUpdateTimerSeconds
    ) {
      this.setAutoUpdateTimer();
    }
    //Setup columnList for report filters
    this.columnsList = getColumnsFromDatasource(this.state.selectedDatasource).filter((r) => {
      if (r.type == "COLUMN") {
        return r.value.indexOf("qa_") != 0;
      } else if (r.type == "MEASURE") {
        return true;
      }
    });
    if (this.props.reports.length > 0 && (this.state.report.length === 0 || this.state.report[0]?.id == null)) {
      const report = this.props.reports.filter((rep) => rep.id === this.props.match.params.id);
      if (report.length) {
        this.setState({
          ...this.state,
          report: report,
          newReportName: report[0].name,
          selectedDatasourceId: report[0].datasourceId ? report[0].datasourceId : "",
          shouldFilterBy: report[0].filterBy ? report[0].filterBy : null,
          autoUpdateTimer: report[0].autoUpdateTimer ? report[0].autoUpdateTimer : null,
          reportOriginalSavedPages: report[0].pages,
          tempReportPages: report[0].pages,
          colorPalette:
            report[0].colorPalette && report[0].colorPalette.length > 5
              ? report[0].colorPalette
              : ["#36A2EB", "#FF6384", "#4BC0C0", "#FF9F40", "#9966FF", "#FFCD56", "#C9CBCF", "0.6"],
          filters: report[0].filters,
          metaReportReports: report[0].metaReportReports,
        });

        if (this.state.URLFilterDataBy) {
          this.shouldFilterBy(this.state.URLFilterDataBy);
          this.simulateFilterBy(getClientFilterByInfo());
        } else if (report[0].filterBy) {
          this.shouldFilterBy(report[0].filterBy);
        }

        if (report[0].datasourceId) {
          this.fetchDatasources(report[0].datasourceId);
        }
        // this.props.report = report;
      }
    } else if (
      this.state.report != null &&
      this.state.report.length &&
      this.state.report[0].id != null &&
      !this.state.reportOriginalPagesSetup
    ) {
      this.setState({
        reportOriginalPagesSetup: true,
        reportOriginalSavedPages: this.state.report[0].pages != null ? this.state.report[0].pages : [],
      });
    }
  }

  getReportFromProps() {
    if (this.props.report && this.props.report.length && this.props.report[0] !== undefined) {
      return this.props.report[0];
    } else {
      return generateReport();
    }
  }

  shouldFilterBy(filterBy) {
    this.setState({
      shouldFilterBy: filterBy,
      simulateFilterBy: null,
    });

    if (this.state.URLFilterDataBy == null) {
      switch (filterBy) {
        case ORG:
          getOrganizations().then((data) => {
            this.setState({ simulateFilterData: data });
          });
          break;
        case USER:
          getClients().then((data) => {
            this.setState({ simulateFilterData: data });
          });
          break;
        default:
          this.setState({ simulateFilterData: [] });
          break;
      }
    }
  }

  switchDataMode() {
    if (this.state.dataMode == "dev") {
      this.setState({
        dataMode: "pro",
      });
    } else {
      this.setState({
        dataMode: "dev",
      });
    }
  }

  switchReportMode() {
    if (this.state.mode == "edit") {
      this.setState({
        mode: "view",
      });
      this.toggleExpandCollapse();
    } else {
      this.setState({
        mode: "edit",
      });
      setTimeout(() => {
        this.toggleExpandCollapse();
      }, 1000);
    }
  }

  onColorPaletteChange(newColorPalette) {
    this.setState({
      colorPalette: newColorPalette,
    });
  }

  simulateFilterBy(filterBy) {
    this.setState({
      simulateFilterBy: filterBy,
    });
    if (this.state.mode == "edit") {
      setTimeout(() => {
        this.toggleExpandCollapse();
      }, 1000);
    }
  }

  clientReportPermissions(permissions) {
    permissions.then((response) => {
      // Container does not exist
      if (response.status === 404) {
        this.setState({
          clientReportPermissions: null,
        });
      } else {
        this.setState({
          clientReportPermissions: response,
        });
      }
    });
  }

  checkIfReportIsDirty() {
    let isReportDirtyFlag;
    if (this.reportOriginalSavedState != null && this.state.report != null) {
      const reportCurrentState = JSON.stringify({
        name: this.state.newReportName,
        description: this.state.newReportDescription,
        tags: this.state.newReportTags,
        autoUpdateTimer: this.state.autoUpdateTimer,
        datasourceId: this.state.selectedDatasourceId,
        filterBy: this.state.shouldFilterBy,
        colorPalette: this.state.colorPalette,
        filters: this.state.filters,
        metaReportReports: this.state.metaReportReports,
      });
      if (this.reportOriginalSavedState == reportCurrentState) {
        if (this.state.reportOriginalSavedPages != null) {
          if (
            JSON.stringify(this.state.reportOriginalSavedPages) ==
            JSON.stringify(this.state.tempReportPages != null ? this.state.tempReportPages : [])
          ) {
            isReportDirtyFlag = false;
          } else {
            isReportDirtyFlag = true;
          }
        } else {
          isReportDirtyFlag = false;
        }
      } else {
        isReportDirtyFlag = true;
      }
    } else {
      isReportDirtyFlag = false;
    }
    if (this.state.isReportDirty != isReportDirtyFlag) {
      this.setState({ isReportDirty: isReportDirtyFlag });
    }
  }

  updateReport(e) {
    let newRep = {
      id: this.state.report[0].id,
      name: this.state.newReportName,
      description: this.state.newReportDescription,
      tags: this.state.newReportTags,
      autoUpdateTimer: this.state.autoUpdateTimer,
      datasourceId: this.state.selectedDatasourceId,
      filterBy: this.state.shouldFilterBy,
      pages: this.state.tempReportPages,
      colorPalette: this.state.colorPalette,
      filters: this.state.filters,
      metaReportReports: this.state.metaReportReports,
      modifiedAt: new Date().getTime(),
    };

    updateReport({
      ...newRep,
      pages: this.state.tempReportPages,
    });

    this.reportOriginalSavedState = JSON.stringify({
      name: this.state.newReportName,
      description: this.state.newReportDescription,
      tags: this.state.newReportTags,
      autoUpdateTimer: this.state.autoUpdateTimer,
      datasourceId: this.state.selectedDatasourceId,
      filterBy: this.state.shouldFilterBy,
      colorPalette: this.state.colorPalette,
      filters: this.state.filters,
      metaReportReports: this.state.metaReportReports,
    });
    this.setState({
      reportOriginalSavedPages:
        this.state.tempReportPages != null ? JSON.parse(JSON.stringify(this.state.tempReportPages)) : [],
      isReportDirty: false,
    });

    e.preventDefault();
  }

  publishReport(e) {
    if (
      this.state.shouldFilterBy != null &&
      this.state.simulateFilterBy != null &&
      this.state.selectedDatasource != null
    ) {
      const databaseId =
        this.state.shouldFilterBy == "ORG" ? this.state.simulateFilterBy.id : this.state.simulateFilterBy.organization;

      this.setState({ isPublishingReport: true });
      this.togglePublishingReportModal();
      publishReport(databaseId, this.state.selectedDatasource)
        .then((publishResponse) => {
          this.setState({ isPublishingReport: false });
          this.togglePublishedReportModal();
        })
        .catch((e) => {
          this.setState({ isPublishingReport: false });
          publishNotification({
            type: "alert",
            message: "Error publishing report ❌",
          });
          console.error(e);
        });
    }
  }

  isContentEmpty() {
    return (
      this.state.selectedDatasource == null ||
      Object.keys(this.state.selectedDatasource).length === 0 ||
      this.state.shouldFilterBy == null ||
      this.state.simulateFilterBy == null
    );
  }

  onDatasourceChange(e) {
    this.setState({
      selectedDatasource: e,
      selectedDatasourceId: e.id,
      shouldFilterBy: null,
      simulateFilterBy: null,
      simulateFilterData: null,
    });
    //this.filterByRef.current.reset();
  }

  onReportEditorChange(newPages) {
    this.setState({
      tempReportPages: newPages,
    });
  }

  onGlobalFiltersChange(newFilters) {
    this.setState({
      report: [
        {
          ...this.state.report[0],
          filters: newFilters,
        },
      ],
      filters: newFilters,
    });
  }

  togglePublishingReportModal() {
    this.setState({
      isPublishingReportModalOpen: !this.state.isPublishingReportModalOpen,
    });
  }

  togglePublishedReportModal() {
    this.setState({
      isPublishedReportModalOpen: !this.state.isPublishedReportModalOpen,
    });
  }

  toggleImportingReportModal() {
    this.setState({
      isImportingReportModalOpen: !this.state.isImportingReportModalOpen,
    });
  }

  clearDataCache() {
    const databaseId =
      this.state.shouldFilterBy == "ORG" ? this.state.simulateFilterBy.id : this.state.simulateFilterBy.organization;

    clearOrgDataCache(databaseId, this.state.dataMode).then((_) => {
      this.props.publishNotification({
        type: "info",
        message: `Cache Cleared (${this.state.dataMode})`,
      });
    });
  }

  importReport() {
    const reportToImport = this.state.reportToImport;
    publishNotification({
      type: "info",
      message: "Report imported ✅",
    });
    this.setState({
      report: [
        {
          ...this.state.report[0],
          pages: reportToImport.pages,
        },
      ],
      reportToImport: null,
    });
    this.toggleImportingReportModal();
  }

  setReportToImport(report) {
    this.setState({
      reportToImport: report,
    });
  }

  handleZoom(signal) {
    var kpiList = document.getElementsByClassName("viewMode");

    let currentZoom = kpiList[0].style.zoom;
    if (currentZoom == null || currentZoom == "") {
      currentZoom = "100%";
    }
    let newZoom = Number(currentZoom.replace("%", ""));
    if (signal == "-") {
      newZoom = newZoom - 10 + "%";
    }
    if (signal == "+") {
      if (newZoom == 100) {
        return;
      }
      newZoom = newZoom + 10 + "%";
    }
    for (let kpi of kpiList) {
      kpi.style.zoom = newZoom;
    }
    setTimeout(() => {
      let tableKPIElements1 = document.getElementsByClassName("InovuaReactDataGrid__column-layout");
      let tableKPIElements2 = document.getElementsByClassName("InovuaReactDataGrid__virtual-list");
      let tableKPIFooterElements = document.getElementsByClassName("InovuaReactDataGrid__footer-rows-container");
      if (tableKPIFooterElements.length) {
        for (let tableEl of tableKPIElements1) {
          tableEl.style.height = "calc(100% - 34px)";
        }
        setTimeout(() => {
          for (let tableEl of tableKPIElements2) {
            tableEl.style.height = "100%";
          }
        }, 1000);
      } else {
        for (let tableEl of tableKPIElements1) {
          tableEl.style.height = "100%";
        }
        for (let tableEl of tableKPIElements2) {
          tableEl.style.height = "100%";
        }
      }
    }, 1000);
  }

  getParsedUpdateAutoTimer(timerSeconds) {
    switch (true) {
      case timerSeconds > 240:
        return "1 minuto";
      case timerSeconds > 180:
        return "2 minutos";
      case timerSeconds > 120:
        return "3 minutos";
      case timerSeconds > 0:
        return "4 minutos";
      default:
        break;
    }
  }

  render() {
    return (
      <Wrapper>
        {/* <div>Report {this.props.match.params.id}</div> */}

        <TopControls onSubmit={this.updateReport}>
          <MetaForm>
            <Input
              placeholder="Report name"
              value={this.state.newReportName}
              onChange={(e) => this.setState({ newReportName: e.target.value })}
            />
            <Input
              placeholder="Report description"
              value={this.state.newReportDescription}
              onChange={(e) => this.setState({ newReportDescription: e.target.value })}
            />
            <Input
              placeholder="Report tags"
              value={this.state.newReportTags}
              onChange={(e) => this.setState({ newReportTags: e.target.value })}
            />
            {/* <KSTKSelect
              placeholder={<div>Category</div>}
              isMulti={false}
              isSearchable={true}
              isClearable={true}
              options={this.state.categories?.sort((a, b) => (a.name.localeCompare(b.name, "pt") == 1 ? 1 : -1))}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
              onChange={(o) => (o != null ? this.setState({ category: o.id }) : this.setState({ category: null }))}
              value={
                this.state.category != null && this.state.categories != null
                  ? this.state.categories.filter((c) => c.id == this.state.category)[0]
                  : null
              }
            /> */}
            <KSTKSelect
              placeholder={<div>Datasource</div>}
              isMulti={false}
              isSearchable={true}
              isClearable={true}
              options={this.props.datasources?.sort((a, b) => (a.name.localeCompare(b.name, "pt") == 1 ? 1 : -1))}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
              isDisabled={this.props.datasources == null || this.props.datasources.length == 0 ? true : false}
              onChange={(datasource) =>
                datasource != null ? this.onDatasourceChange(datasource) : this.onDatasourceChange({})
              }
              value={
                this.state.selectedDatasource != null
                  ? this.props.datasources.filter((d) => d.id == this.state.selectedDatasource.id)[0]
                  : null
              }
            />
            {/* <KSTKDropdown
              label="Datasource"
              entryLabel={(e) => e.name}
              data={this.props.datasources}
              onSelect={this.onDatasourceChange}
              selected={this.state.selectedDatasource ? this.state.selectedDatasource.name : null}
            /> */}
            <KSTKSelect
              placeholder={<div>Filter by</div>}
              isMulti={false}
              isClearable={true}
              options={this.filters}
              getOptionValue={(o) => o.value}
              onChange={(filteredBy) =>
                filteredBy != null ? this.shouldFilterBy(filteredBy.value) : this.shouldFilterBy(null)
              }
              value={
                this.state.shouldFilterBy != null
                  ? this.filters.filter((f) => f.value == this.state.shouldFilterBy)
                  : null
              }
            />
            {this.state.reports != null && this.state.reports.length ? (
              <KSTKSelect
                index={"metaReportReportsDropdown"}
                placeholder={<div>Meta report reports</div>}
                isMulti={true}
                isClearable={true}
                options={this.state.reports
                  .map((r) => {
                    return { label: r.name, value: r.id };
                  })
                  .sort(function (a, b) {
                    if (a["label"].localeCompare(b["label"], "pt") == -1) {
                      return -1;
                    }
                    if (a["label"].localeCompare(b["label"], "pt") == 1) {
                      return 1;
                    }
                  })}
                components={{ MultiValue }}
                /* getOptionValue={(r) => r.id} */
                /* getOptionLabel={(r) => r.name} */
                onChange={(reps) =>
                  reps != null && reps.length
                    ? this.setState({ metaReportReports: reps.map((r) => r.value) })
                    : this.setState({ metaReportReports: null })
                }
                value={
                  this.state.metaReportReports != null
                    ? this.state.metaReportReports
                      .map((r) => {
                        let filteredReport = this.state.reports.filter((el) => el.id == r);
                        if (filteredReport.length) {
                          return filteredReport[0];
                        } else {
                          return { label: "error", value: "error" };
                        }
                      })
                      .map((r) => {
                        return { label: r.name, value: r.id };
                      })
                    : null
                }
                onFocus={stopEventPropragation}
              />
            ) : null}
            <KSTKSelect
              placeholder={<div>Auto update timer</div>}
              isMulti={false}
              isClearable={true}
              options={this.autoUpdateTimers}
              getOptionValue={(o) => o.value}
              onChange={(autoUpTimer) =>
                autoUpTimer != null && autoUpTimer != ""
                  ? this.setState({ autoUpdateTimer: autoUpTimer.value })
                  : this.setState({ autoUpdateTimer: null })
              }
              value={
                this.state.autoUpdateTimer != null
                  ? this.autoUpdateTimers.filter((t) => t.value == this.state.autoUpdateTimer)
                  : null
              }
            />
            {/* <KSTKDropdown
              label="Filter by"
              entryLabel={(e) => e}
              data={this.filters}
              onSelect={this.shouldFilterBy}
              selected={this.state.shouldFilterBy}
            /> */}
            <KSTKColorPalettePicker
              colorPalette={this.state.colorPalette}
              onChange={this.onColorPaletteChange}
            ></KSTKColorPalettePicker>
            <DevProdSwitch switch>
              <span>QA</span>
              <Input
                type="switch"
                checked={this.state.dataMode === "pro"}
                role="switch"
                onChange={(e) => this.switchDataMode()}
              />
              <span>PROD</span>
            </DevProdSwitch>
          </MetaForm>
          <FormActions>
            <Button type="submit" color="primary" disabled={!this.state.isReportDirty}>
              Save <i className="fa-regular fa-floppy-disk" />
            </Button>
            {this.state.selectedDatasource?.sourceType == "postgres" ? (
              !this.state.isPublishingReport ? (
                <Button
                  color="primary"
                  onClick={() => this.togglePublishingReportModal()}
                  disabled={this.state.simulateFilterBy == null}
                >
                  Publish Data <i className="fa-regular fa-paper-plane" />
                </Button>
              ) : (
                <Button color="primary" onClick={() => this.publishReport()} disabled={true}>
                  Publishing Data<Spinner size="sm">Loading...</Spinner>
                </Button>
              )
            ) : null}
            {this.state.metaReportReports == null ? (
              <Button
                color="primary"
                onClick={() => this.toggleImportingReportModal()}
                disabled={this.state.simulateFilterBy == null}
              >
                Import Report <i className="fa-solid fa-file-import" />
              </Button>
            ) : null}
            <Modal
              isOpen={this.state.isPublishingReportModalOpen}
              toggle={() => this.togglePublishingReportModal()}
              centered={true}
            >
              <ModalHeader toggle={() => this.togglePublishingReportModal()}>
                Do you want publish the report?
              </ModalHeader>
              <ModalBody>
                This will overwrite all the production tables of this organization that have a QA table.
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.publishReport()}>
                  Publish
                </Button>{" "}
                <Button color="secondary" onClick={() => this.togglePublishingReportModal()}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={this.state.isPublishedReportModalOpen}
              toggle={() => this.togglePublishedReportModal()}
              centered={true}
            >
              <ModalHeader toggle={() => this.togglePublishedReportModal()}>Report published!</ModalHeader>
              <ModalFooter>
                <Button color="primary" onClick={() => this.togglePublishedReportModal()}>
                  Ok
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.isImportingReportModalOpen}
              toggle={() => this.toggleImportingReportModal()}
              centered={true}
            >
              <ModalHeader toggle={() => this.toggleImportingReportModal()}>{this.state.report[0].name}</ModalHeader>
              <ModalBody>
                <p>Select the report you'd like to import the structure from:</p>
                <KSTKSelect
                  id="report"
                  placeholder={<div>Target Reports</div>}
                  isMulti={false}
                  isSearchable={true}
                  isClearable={true}
                  options={this.props.reports}
                  getOptionLabel={(o) => o.name}
                  getOptionValue={(o) => o.id}
                  isDisabled={this.props.reports == null || this.props.reports.length == 0 ? true : false}
                  onChange={(rep) => this.setReportToImport(rep)}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => this.importReport()}
                  disabled={this.state.reportToImport == null}
                >
                  Import
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    this.toggleImportingReportModal();
                    this.setState({ reportToImport: null });
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            {/* <Button onClick={this.dele}>
              <i className="fas fa-trash" />
            </Button> */}
          </FormActions>
        </TopControls>
        <ReportControls>
          <SimulationForm>
            {/* <KSTKDropdown
              label="Filter by"
              entryLabel={(e) => e.name}
              data={this.state.simulateFilterData}
              onSelect={this.simulateFilterBy}
              ref={this.filterByRef}
            /> */}
            <label>Simulate</label>
            <KSTKSelect
              placeholder={<div>Simulate</div>}
              dropdownIndicator="search"
              isMulti={false}
              isSearchable={true}
              isClearable={true}
              options={this.state.simulateFilterData?.sort((a, b) =>
                a.name.localeCompare(b.name, "pt") == 1 ? 1 : -1
              )}
              getOptionLabel={(o) =>
                this.state.shouldFilterBy != null && this.state.shouldFilterBy == "ORG"
                  ? o.name
                  : o.name + " " + o.surname + " - " + o.email
              }
              getOptionValue={(o) => o.id}
              isDisabled={
                this.state.simulateFilterData == null || this.state.simulateFilterData.length == 0 ? true : false
              }
              onChange={this.simulateFilterBy}
              value={this.state.simulateFilterBy}
            />
          </SimulationForm>
          <AlwaysPresentControls
            ref={this.alwaysPresentControlsRef}
            className={classnames({
              viewMode: this.state.mode === "view",
            })}
          >
            {this.isContentEmpty() ? null : (
              <div>
                {this.state.expandCollapseFlag ? (
                  <React.Fragment>
                    <DevProdSwitch switch>
                      <span>QA</span>
                      <Input
                        type="switch"
                        checked={this.state.dataMode === "pro"}
                        role="switch"
                        onChange={(e) => this.switchDataMode()}
                      />
                      <span>PROD</span>
                    </DevProdSwitch>
                    <Button onClick={this.switchReportMode} color="primary">
                      View <i className="fa-solid fa-eye" />
                    </Button>
                    <Button onClick={this.updateReport} color="primary" disabled={!this.state.isReportDirty}>
                      Save <i className="fa-regular fa-floppy-disk" />
                    </Button>
                    {!this.state.isPublishingReport ? (
                      <Button
                        color="primary"
                        onClick={() => this.togglePublishingReportModal()}
                        disabled={this.state.simulateFilterBy == null}
                      >
                        Publish Data
                        <i className="fa-regular fa-paper-plane" />
                      </Button>
                    ) : (
                      <Button color="primary" onClick={() => this.publishReport()} disabled={true}>
                        Publishing Data<Spinner size="sm">Loading...</Spinner>
                      </Button>
                    )}
                    {this.state.metaReportReports == null ? (
                      <Button
                        color="primary"
                        onClick={() => this.toggleImportingReportModal()}
                        disabled={this.state.simulateFilterBy == null}
                      >
                        Import Report <i className="fa-solid fa-file-import" />
                      </Button>
                    ) : null}
                    <Button
                      color="primary"
                      onClick={() => this.clearDataCache()}
                      disabled={this.state.simulateFilterBy == null}
                    >
                      Clear Data Cache <i className="fa-solid fa-arrows-rotate" />
                    </Button>
                  </React.Fragment>
                ) : null}
                <Button onClick={this.toggleExpandCollapse}>
                  <i className="fas fa-expand-arrows-alt" />
                </Button>
              </div>
            )}
          </AlwaysPresentControls>
        </ReportControls>
        <ReportSetupWrapper>
          {this.isContentEmpty() ? (
            <KSTKEmptyContent message="Simulation subject not selected" icon="magnifying-glass-chart" />
          ) : (
            <ReportSetup
              ref={this.reportSetupRef}
              className={classnames({
                expanded: this.state.mode === "view",
                editMode: this.state.mode === "edit",
              })}
            >
              <ReportSetupNav
                className={classnames({
                  viewMode: this.state.mode === "view",
                })}
              >
                <Nav tabs>
                  <NavItem>
                    <ReportNavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTabs("1");
                      }}
                    >
                      Report
                    </ReportNavLink>
                  </NavItem>
                  <NavItem>
                    <ReportNavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggleTabs("2");
                      }}
                    >
                      Global Filters
                    </ReportNavLink>
                  </NavItem>
                </Nav>
              </ReportSetupNav>
              <ReportDetailTabContent activeTab={this.state.activeTab}>
                <ReportDetailTabPane tabId="1">
                  {this.state.mode === "view" ? (
                    <ZoomPane>
                      <span>Zoom:</span>
                      <Button
                        onClick={() => {
                          this.handleZoom("-");
                        }}
                        color="primary"
                        size="sm"
                      >
                        <i className="fas fa-minus" />
                      </Button>
                      <Button
                        onClick={() => {
                          this.handleZoom("+");
                        }}
                        color="primary"
                        size="sm"
                      >
                        <i className="fas fa-plus" />
                      </Button>
                    </ZoomPane>
                  ) : null}
                  {this.state.mode === "view" && this.state.autoUpdateTimer != null ? (
                    <AutoUpdateTimerPane>
                      <span>
                        Atualizado<br></br>há {this.getParsedUpdateAutoTimer(this.state.autoUpdateTimerSeconds)}
                      </span>
                    </AutoUpdateTimerPane>
                  ) : null}
                  <KSTKReportEditor
                    ref={this.reportEditorRef}
                    report={this.state.report}
                    metaReportPages={this.state.metaReportPages}
                    datasource={this.state.selectedDatasource}
                    colorPalette={this.state.colorPalette}
                    selected={this.state.simulateFilterBy}
                    filter={this.state.shouldFilterBy}
                    onChange={this.onReportEditorChange}
                    mode={this.state.mode}
                    dataMode={this.state.dataMode}
                    switchReportMode={this.switchReportMode}
                    clientReportPermissions={this.state.clientReportPermissions}
                    autoUpdateTimerFlag={this.state.autoUpdateTimerFlag}
                  />
                </ReportDetailTabPane>
                <ReportDetailTabPane tabId="2">
                  <KSTKFilterField
                    options={this.columnsList}
                    onChange={this.onGlobalFiltersChange}
                    values={this.state.filters}
                    isDisabled={false}
                  ></KSTKFilterField>
                </ReportDetailTabPane>
              </ReportDetailTabContent>
            </ReportSetup>
          )}
        </ReportSetupWrapper>
      </Wrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
