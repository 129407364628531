import React, { useState, useEffect } from "react";
import { UncontrolledAlert } from "reactstrap";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  width: 300px;
  margin: 10px;
  max-height: 100%;
  z-index: 999;
`;

const typeToColorMap = {
  info: "info",
  alert: "danger",
};

export default function KSTKNotifications(props) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  useEffect(() => {
    dismissNotifications();
  }, [props.notifications]);

  const dismissNotifications = () => {
    props.notifications.map((n) => {
      if (n.id !== "export-table-data") {
        setTimeout(() => {
          props.removeNotification(n.id);
        }, 3000);
      }
    });
  };

  return (
    <Wrapper>
      {window.self == window.top &&
        props.notifications.map((n) => {
          if ((n.type === "info" && urlParams.get("mode") == "view") || urlParams.get("mode") == "edit") {
            return (
              <UncontrolledAlert
                id={n.id}
                key={n.id}
                color={typeToColorMap[n.type]}
                toggle={() => props.removeNotification(n.id)}
              >
                {n.message}
              </UncontrolledAlert>
            );
          }
        })}
    </Wrapper>
  );
}
