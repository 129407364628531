import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import { PropsRoute, ProtectedRoute } from "./util/UtilRoutes";
import styled from "styled-components";
import { connect } from "react-redux";
// import logo from './logo.svg';
import "./App.css";

import KSTKNavbar from "./components/KSTKNavbar";

import Login from "./pages/Login";
import ReportList from "./pages/ReportList";
import Report from "./pages/Report";
import DataSourceList from "./pages/DatasourceList";
import DataSource from "./pages/DataSource";

import { invalidateAuth, logout, fetchAuthentication } from "./services/Client";
import { fetchReports, searchReports, selectReport, createReport, deleteReport } from "./actions/ReportActions";
import {
  createDataSource,
  fetchDataSources,
  searchDataSources,
  selectDataSource,
  deleteDataSource,
  upsertSource,
  removeSource,
  setSources,
  upsertRelation,
  removeRelation,
  updateMetadata,
  updateDatasource,
  upsertVariable,
  removeVariable,
} from "./actions/DataSourceActions";
import { publishNotification, publishProgressNotification, removeNotification } from "./actions/NotificationActions";
import KSTKNotifications from "./components/KSTKNotifications";

import { getReports } from "./services/report.service";
import { getDatasources } from "./services/datasource.service";

const mapDispatchToProps = (dispatch) => ({
  createDataSource: (datasource) => dispatch(createDataSource(datasource)),
  fetchReports: (reports) => dispatch(fetchReports(reports)),
  selectReport: (id) => dispatch(selectReport(id)),
  searchReports: (search) => dispatch(searchReports(search)),
  createReport: (report) => dispatch(createReport(report)),
  deleteReport: (id) => dispatch(deleteReport(id)),
  fetchDataSources: (data) => dispatch(fetchDataSources(data)),
  searchDataSources: (search) => dispatch(searchDataSources(search)),
  selectDataSource: (id) => dispatch(selectDataSource(id)),
  updateDatasource: (datasource) => dispatch(updateDatasource(datasource)),
  deleteDataSource: (id) => dispatch(deleteDataSource(id)),
  upsertSource: (source, datasourceId) => dispatch(upsertSource(source, datasourceId)),
  removeSource: (source, datasourceId) => dispatch(removeSource(source, datasourceId)),
  setSources: (sources, sourceType, datasourceId) => dispatch(setSources(sources, sourceType, datasourceId)),
  upsertRelation: (relation, datasourceId) => dispatch(upsertRelation(relation, datasourceId)),
  removeRelation: (relation, datasourceId) => dispatch(removeRelation(relation, datasourceId)),
  updateMetadata: (metadata, datasourceId) => dispatch(updateMetadata(metadata, datasourceId)),
  publishNotification: (metadata) => dispatch(publishNotification(metadata)),
  publishProgressNotification: (metadata, id) => dispatch(publishProgressNotification(metadata, id)),
  removeNotification: (id) => dispatch(removeNotification(id)),
  upsertVariable: (variable, datasourceId) => dispatch(upsertVariable(variable, datasourceId)),
  removeVariable: (variable, datasourceId) => dispatch(removeVariable(variable, datasourceId)),
});

const mapStateToProps = (state) => ({
  ...state,
});

const PageWrapper = styled.div`
  height: 100%;
`;

const PageContent = styled.div`
  height: calc(100% - 56px);
  padding: 10px;

  &.login {
    height: 100%;
    background: url("../assets/login_background.jpg");
    background-size: cover;
  }
`;

class App extends Component {
  constructor(props) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    super(props);
    this.state = {
      isLoggedIn: false,
      loggedUserData: null,
      visibility: window.self !== window.top || urlParams.get("mode") == "view" ? "hidden" : "visible",
    };

    setTimeout((_) => {
      this.setState({ visibility: "visible" });
    }, 1000);
  }

  authenticate = (loggedUserData) => {
    this.setState({
      isLoggedIn: true,
      loggedUserData: loggedUserData.value,
    });

    getReports().then((data) => this.props.fetchReports(data));
    getDatasources().then((data) => {
      this.props.fetchDataSources(data);
    });
  };

  logout = () => {
    logout(this.state.loggedUserData.id).then((data) => {
      invalidateAuth();
      this.setState({
        isLoggedIn: false,
        loggedUserData: null,
      });
    });
  };

  reformAuthentication = () => {
    const authData = fetchAuthentication();
    if (authData) {
      this.authenticate(authData);
      console.log("already logged in... redirecting 🚀");

      return true;
    }

    return false;
  };

  isLoggedIn = () => {
    if (fetchAuthentication() == null) {
      return false;
    }
    return this.state.isLoggedIn || this.reformAuthentication();
  };

  render() {
    return (
      <Router>
        <PageWrapper className="pageWrapper" style={{ visibility: this.state.visibility }}>
          {this.isLoggedIn() ? (
            <div>
              <KSTKNavbar
                loggedUserData={this.state.loggedUserData}
                logout={this.logout}
                reportCount={this.props.ReportReducer.reports.length}
                datasourceCount={this.props.DataSourceReducer.datasources.length}
              />
              <KSTKNotifications
                notifications={this.props.NotificationReducer.notifications}
                removeNotification={this.props.removeNotification}
              ></KSTKNotifications>
            </div>
          ) : null}
          <PageContent className={this.isLoggedIn() ? "" : "login"}>
            <Switch>
              <PropsRoute
                exact
                path="/login"
                component={Login}
                isLoggedIn={this.state.isLoggedIn}
                authenticate={this.authenticate}
              />
              <ProtectedRoute
                exact
                path="/reports/list"
                component={ReportList}
                isLoggedIn={this.state.isLoggedIn}
                fetchReports={this.props.fetchReports}
                searchReports={this.props.searchReports}
                reports={this.props.ReportReducer.filteredReports}
                selectReport={this.props.selectReport}
                selectedReport={[this.props.ReportReducer.selectedReport]}
                createReport={this.props.createReport}
                deleteReport={this.props.deleteReport}
              />
              <ProtectedRoute
                path="/reports/:id"
                component={Report}
                isLoggedIn={this.state.isLoggedIn}
                report={[this.props.ReportReducer.selectedReport]}
                datasources={this.props.DataSourceReducer.datasources}
                fetchDataSources={this.props.fetchDataSources}
                reports={this.props.ReportReducer.filteredReports}
              />
              <ProtectedRoute
                path="/datasources/list"
                component={DataSourceList}
                isLoggedIn={this.state.isLoggedIn}
                createDataSource={this.props.createDataSource}
                fetchDataSources={this.props.fetchDataSources}
                searchDataSources={this.props.searchDataSources}
                selectDataSource={this.props.selectDataSource}
                datasources={this.props.DataSourceReducer.filteredDatasources}
                selectedDataSource={[this.props.DataSourceReducer.selectedDatasource]}
                deleteDataSource={this.props.deleteDataSource}
              />
              <ProtectedRoute
                path="/datasources/:id"
                component={DataSource}
                isLoggedIn={this.state.isLoggedIn}
                datasource={[this.props.DataSourceReducer.selectedDatasource]}
                upsertSource={this.props.upsertSource}
                removeSource={this.props.removeSource}
                setSources={this.props.setSources}
                upsertRelation={this.props.upsertRelation}
                upsertVariable={this.props.upsertVariable}
                removeRelation={this.props.removeRelation}
                updateMetadata={this.props.updateMetadata}
                updateDatasource={this.props.updateDatasource}
                removeVariable={this.props.removeVariable}
              />
              <Redirect to="/login" />
            </Switch>
          </PageContent>
        </PageWrapper>
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
