import { getCookie, setCookie, eraseCookie } from "./cookie.service";
import { handleErrors } from "./Util";

export const BASE_URL = "/api";

export function login(email, password) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      ttl: 9120738932,
    }),
  };

  return fetch(`${BASE_URL}/clients/login?include=user`, config).then(handleErrors);
}

export function logout(token) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      accessToken: token,
    }),
  };

  return fetch(`${BASE_URL}/clients/logout`, config);
}

export function persistAuthentication(userDetails) {
  userDetails = {
    value: userDetails,
    timestamp: new Date().getTime(),
    ttl: userDetails.ttl,
  };
  setCookie("KSTKAuth", JSON.stringify(userDetails), 0.333);
  // localStorage.setItem("KSTKReportBuilderAuth", JSON.stringify(userDetails));
}

export function fetchAuthentication() {
  const params = new URLSearchParams(window.location.search);
  const isPublic = params.get("isPublic") === "true";
  const orgId = params.get("orgId");

  if (isPublic) {
    return {
      value: {
        id: null,
        user: {
          name: null,
          surname: null,
          organization: orgId,
        },
        profile: "public",
      },
      timestamp: null,
      ttl: null,
      isPublic: true,
    };
  }

  let userDetails = getCookie("KSTKAuth") ? JSON.parse(getCookie("KSTKAuth")) : null;
  if (
    userDetails == null ||
    (window.location.pathname.indexOf("/reports/list") != -1 &&
      userDetails.value.user != null &&
      userDetails.value.user.profile != null &&
      userDetails.value.user.profile != "admin")
  ) {
    invalidateAuth();
    return null;
  }
  if (userDetails) {
    let { timestamp, ttl } = userDetails;
    if (new Date(timestamp).getTime() + ttl * 1000 > new Date().getTime()) {
      return userDetails;
    } else {
      invalidateAuth();
      return null;
    }
  }

  return null;
}

export function invalidateAuth() {
  eraseCookie("KSTKAuth");
}
